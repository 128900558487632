import React, { useEffect, useState } from "react";
import "./IntroAppBarLogo.scss";
import iconoanteia from "../../assets/v10/anteiaR.svg";
import BottomAppBar from "../AppBar/BottomAppBar";
import { useHistory } from "react-router-dom";

export default function IntroAppBarLogo({urlCode}){
    let history = useHistory();
    const [textoContinuarHabilitado, setTextoContinuarHabilitado] = useState(false);
    const [botonConfirmarColor, setBotonConfirmarColor] = useState("");
    const [bottomAppBarHeight, setBottomAppBarHeight] = useState("");
    useEffect(()=>{
        if(bottomAppBarHeight !== "")
        {
            var tamanio = 100 - bottomAppBarHeight.split("%")[0];
            document.getElementById("contenedorIntroAppBarLogo").style.height = tamanio + "vh";
        }
        else{
            setTimeout(() => {
                setTextoContinuarHabilitado(true);
                setBotonConfirmarColor("blue")
            }, 1000);
        }        
    }, [textoContinuarHabilitado, botonConfirmarColor, bottomAppBarHeight])

    function nextScreen(){
        history.push(`introcheck/?code=${urlCode}`);
    }
    
    function handleCallback(bottomAppBar){
        setBottomAppBarHeight(bottomAppBar);
    }

    return (
        <div id="contenedorIntroAppBarLogo" className="contenedorIntroAppBarLogo">
            <img className="imgLogo" src={iconoanteia} loading="eager" alt="logo anteia"/>
            <p className="speechBubble">Esta es tu nueva barra de navegación, con ella podrás avanzar, pausar o continuar tu proceso de manera mas sencilla.</p>
            {textoContinuarHabilitado && (
                <p className="continueMessage">Para continuar oprime el icono Confirmar</p>
            )}
            {botonConfirmarColor === "" && (
                <BottomAppBar parentCallback={handleCallback} colorConfirmar={botonConfirmarColor} botonAnteriorHabilitado={true} botonSiguienteHabilitado={true} botonConfirmarHabilitado={true} botonOptionsHabilitado={true} botonConfirmarClick={nextScreen}/>
            )}
            {botonConfirmarColor === "blue" && (
                <BottomAppBar parentCallback={handleCallback} colorConfirmar={botonConfirmarColor} botonAnteriorHabilitado={true} botonSiguienteHabilitado={true} botonConfirmarHabilitado={true} botonOptionsHabilitado={true} botonConfirmarClick={nextScreen}/>
            )}
        </div>
    )
}