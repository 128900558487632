// import { IconButton } from "@material-ui/core";
import React, { useState, useEffect } from "react";
// import icon1 from "../../src/static/pass-view-white-asc.png";
import TerminosAdvices from "../TerminosAdvices/TerminosAdvices";
import "./RecolectDataStart.scss";
import { infoMessage } from "../utils/ErrorHandler/errorHandler";
import { useHistory } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import StorageManager from "../utils/StorageManager";
import TopAppBar from "../AppBar/TopAppBar";
import BottomAppBar from "../AppBar/BottomAppBar";

import { Button2 } from "../utils/Buttons/Buttons";


function RecolectDataStart(props) {
  let history = useHistory();

  const [data2, setData] = useState();
  const [selectdata, setSelectData] = useState("1");
  // const needsRut = props.dataRepo.needsRut;
  const needsRut = props.dataRepo.needsRut;
  const docTypes = props.dataRepo.supportedDocTypes;

  const [needsRedirect, setNeedsRedirect] = useState(false);

  const [docType, setDocType] = useState("CEDULA");

  const [index, setIndex] = useState(0);

  const [storageManager, _] = useState(new StorageManager());

  const [needsPhone, setNeedsPhone] = useState(true);
  const [needsName, setNeedsName] = useState(false);
  const [needsLastName, setNeedsLastName] = useState(false);
  const [naturalChecked, setNaturalChecked] = useState(true);
  const [juridicaChecked, setJuridicaChecked] = useState(false);

  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [userDoc, setUserDoc] = useState("");
  const [identificationNumber, setIdentificationNumber] = useState("");
  const [name, setName] = useState("");

  const query = useQuery();
  const UrlCode = query.get("code");

  const [needsPersona, setNeedsPersona] = useState(false);
  const [bottomAppBarHeight, setBottomAppBarHeight] = useState("");
  // console.log('UrlCode',UrlCode)
  function blockDataEntry(data) {
    props.setCanEdit(false);
    if (!data.lastName) {
      // document.getElementById("nombreUser").value = " ";
      // setLastName(" ");
    } else {
      if (document.getElementById("userLastName"))
        document.getElementById("userLastName").disabled = true;
    }

    if (!data.identification) {
      document.getElementById("cedulaUser").value = " ";
    } else {
      document.getElementById("cedulaUser").disabled = true;
    }

    if (!data.email) {
      document.getElementById("correoUser").value = " ";
    } else {
      document.getElementById("correoUser").disabled = true;
    }

    if (needsPhone) {
      if (!data.cellphone) {
        setPhone(" ");
      } else {
        document.getElementById("celularUser").disabled = true;
      }
    }

    if (data.naturalPerson === true || data.naturalPerson === false) {
      document.getElementById("personSelector").disabled = true;
    }

    if (needsRut && data.naturalPerson) {
      document.getElementById("rutUser").disabled = true;
    } else if (!data.naturalPerson) {
      document.getElementById("nitUser").disabled = true;
    }
  }

  useEffect(() => {
    //Skip if not needed
    let project = storageManager.getProject();
    if (project.extraConfig) {
      if (project.extraConfig.askInitialinfo === false) {
        history.push(`/advices?code=${UrlCode}`);
        return;
      }
    }
    if (!data2) {
      // preload data

      props.api.getPreloadedData().then((data) => {
        setData(data);
        console.log(data, "CODEE");
      });
    }
    if(bottomAppBarHeight !== "")
    {
      var tamanio = 100 - bottomAppBarHeight.split("%")[0];
      document.getElementById("recolectStart").style.height = tamanio + "vh";
    }
  }, [bottomAppBarHeight]);

  //needsPhone
  useEffect(() => {
    needsPhoneF();
  }, []);
  async function needsPhoneF() {
    let project = await props.api.getProject();
    if (project.hasOtp === false) {
      setNeedsPhone(false);
    }
    if (project.needsAskLastName === false) {
      setNeedsLastName(false);
    }
    if (project.usesLegalperson === true) {
      setNeedsPersona(true);
    }
  }

  const checkPhoneEmail = async () => {
    let email2 = document.getElementById("correoUser").value;
    let phone2 = phone;
    let res = await props.api.checkPhoneEmail(email2, phone2);
    if (res) {
      if (res.canContinue) {
        final();
      } else {
        infoMessage(
          "El correo o el celular usado ya se encuentra registrado! Por favor revisa los datos e intenta nuevamente"
        );
      }
    } else {
      infoMessage(
        "El correo o el celular usado ya se encuentra registrado! Por favor revisa los datos e intenta nuevamente"
      );
    }
  };

  const final = async () => {
    //load values
    var apellido = lastName;
    var cedula = identificationNumber;
    var celular = phone;
    var correo = document.getElementById("correoUser").value;
    var nombre = name;
    var nit = "";
    var rut = "";
    if (selectdata === "2") {
      nit = document.getElementById("nitUser").value;
    }
    if (selectdata === "1" && needsRut) {
      rut = document.getElementById("rutUser").value;
    }
    var re = /\S+@\S+\.\S+/;
    //check values
    if (correo === "" || celular === "") {
      infoMessage("Por favor completa todos los datos");
      return;
    }
    if (selectdata === "2" && nit === "") {
      infoMessage("Por favor completa todos los datos");
      return;
    }    
    if (selectdata === "1" && needsRut && rut === "") {
      infoMessage("Por favor completa todos los datos");
      return;
    }
    if (!re.test(correo)) {
      infoMessage("Formato de correo inválido.");
      return;
    }
    if (needsPhone) {
      if (celular.length !== 10 && celular.length !== 8) {
        infoMessage("Formato de celular inválido.");
        return;
      }
    }

    if (needsLastName) {
      if (apellido === "") {
        infoMessage("Ingresa tu apellido");
        return;
      }
    }

    if (selectdata === "2" && nit.length !== 9 && nit.length !== 10) {
      infoMessage("NIT invalido.");
      return;
    }

    //verify nombre
    if (needsName) {
      if (nombre === "") {
        infoMessage("Ingresa tu nombre");
        return;
      }
    }
    //send data
    await props.api.addUserInitialInformation(
      cedula,
      apellido,
      correo,
      celular,
      docType,
      nombre
    );
    props.setPhoneEmail(celular, correo);

    if (selectdata === "2") {
      await props.api.sendInitUser(nit);
    }
    if (selectdata === "1" && needsRut) {
      await props.api.setRut(rut);
    }
    // props.nextPage();
    // next();
    history.push(`/advices?code=${UrlCode}`);
    //  history.push(`/final?code=${UrlCode}`);
  };

  const redirectWrongData = async () => {
    try {
      var url = await props.api.getWrongDataUrl();
      window.location = url;
    } catch (e) {
      console.log(e);
    }
  };
  //show the textsinputs

  if (!data2) {
    return (
      <div className="container">
        <div className="row"></div>
      </div>
    );
  }

  const changeDocType = (pDocType) => {
    storageManager.setDocType(pDocType);
    setDocType(pDocType);
  };

  function cambiarValor(e){
    if(e.target.id === "Juridica"){
      setJuridicaChecked(true);
      setNaturalChecked(false);
    }
    else{
      setJuridicaChecked(false);
      setNaturalChecked(true);
    }
  }

  function handleCallback(bottomAppBar){
    setBottomAppBarHeight(bottomAppBar);
  }

  return (
    <div id="recolectStart" className="recolectStart">
      <TopAppBar mensajeAyudaHabilitado={true}/>
      <p className="txt_enter">
        Es momento de<br></br> conocerte<br></br> un poco mas
      </p>
      <h5 className="txt_enter2">Ingresa los siguientes datos:</h5>
      <label htmlFor="Natural" className="radio-label">
        <input
          className="radio-input"
          type="radio"
          id="Natural"
          checked={naturalChecked}
          onChange={cambiarValor}
        />
        <span className="custom-radio" />
        soy persona NATURAL
      </label>
      <label htmlFor="Juridica" className="radio-label">
        <input
          className="radio-input"
          type="radio"
          id="Juridica"
          checked={juridicaChecked}
          onChange={cambiarValor}
        />
        <span className="custom-radio" />
        soy persona JURÍDICA
      </label>
      <input id="celularUser" className="entrada" placeholder="No. CELULAR" type="number" onChange={(e) => {
                let onlyDigitsReg = /^\d+$/;
                if (
                  onlyDigitsReg.test(e.target.value) ||
                  e.target.value.length === 0
                ) {
                  setPhone(e.target.value);
                }
              }} value={phone}/>
      <input id="correoUser" className="entrada" placeholder="CORREO" type="email" maxLength="40"/>
      <BottomAppBar parentCallback={handleCallback} fontSize={global.fontSize} botonSiguienteHabilitado={true} botonOptionsHabilitado={true} botonSiguienteClick={checkPhoneEmail}/>
    </div>
  );
}
export default RecolectDataStart;
