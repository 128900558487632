import React, { useEffect, useState } from "react";
import './TopAppBar.scss';
import LogoAtenea from  '../../assets/v10/VectorLogoAtenea.svg';
import instructivo from '../../assets/v10/instructivo.mp4';

export default function TopAppBar(props){
    const [mensajeAyudaHabilitado, setMensajeAyudaHabilitado] = useState(true);
    const [llamarAtenea, setLlamarAtenea] = useState(false);

    useEffect(()=>{
        console.log(props)
        setMensajeAyudaHabilitado(props.mensajeAyudaHabilitado);
        if(props.llamarAtenea){
            setLlamarAtenea(props.llamarAtenea);
        }
        if(props.mensajeAyudaHabilitado){
            setTimeout(() => {
                setMensajeAyudaHabilitado(false);
            }, 5000);
        }
    }, [props.mensajeAyudaHabilitado])

    function cerrarMensaje(){
        setMensajeAyudaHabilitado(false);
    }

    function getLocation(){
        props.getLocation();
    }

    return(
        <div className="contenedorTop">
            {mensajeAyudaHabilitado && (
                <p className="speech-bubble">Hola soy atenea...<br/>Estoy aquí para ayudarte.<br/>¿tienes alguna consulta?</p>
            )}
            <button className="botonAtena">
                <img src={LogoAtenea} alt="logo atenea" />
            </button>
            {llamarAtenea && (
                <div className="ateneaContenedor" style={{height: "95vh", width: "95vw", display: "flex", justifyContent: "center", flexDirection: "column", margin: "auto", backgroundColor: "white", borderRadius: "20px"}}>
                    <div style={{height: "100vh", width: "70vw", display: "flex", justifyContent: "center", alignItems: "start", flexDirection: "column", margin: "auto", textAlign: "start"}}>
                        <div style={{display: "flex"}}>
                            <h1 style={{fontSize: "3rem", color: "#00BCE2", fontWeight: "bolder", margin: "auto 0 0 0"}}>Hola!</h1>
                            <h3 style={{margin: "auto 0 0.5rem 0.5rem"}}>Andres</h3>
                        </div>
                        <div style={{display: "flex"}}>
                            <div style={{borderLeft: "3px solid #00BCE2", height: "1rem", marginRight: "1rem"}}>
                            </div>
                            <p style={{fontWeight: "lighter", margin: "0 0 1rem 0", fontSize: "0.8rem"}}>
                                Recuerda que debes activar los permisos de ubicación.
                            </p>
                        </div>
                        <div style={{display: "flex"}}>
                            <div style={{borderLeft: "3px solid #00BCE2", height: "1rem", marginRight: "1rem"}}>
                            </div>
                            <p style={{fontWeight: "lighter", margin: "0 0 1rem 0", fontSize: "0.8rem"}}>
                                A continuación te mostramos un video que explica como activarlo.
                            </p>
                        </div>
                        <video style={{backgroundSize: "cover", height: "30%"}} playsInline autoPlay muted src={instructivo} type="video/mp4" loop/>
                        <button className="botonProbarConexion" onClick={getLocation}>Probar Ubicación</button>
                    </div>
                </div>
            )}
        </div>
    )
}