import React, { useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Link, useHistory } from "react-router-dom";
import "./ConfirmEmailScreen.scss";
import Loader from "../utils/Loader/Loader";
import useQuery from "../../hooks/useQuery";
import { successMessage } from "../utils/ErrorHandler/errorHandler";
import StorageManager from "../utils/StorageManager";
import { addEvent } from "../api/Events/EventsApi";
const secondsToWait = 120;

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function getTime() {
  var d = new Date();
  return d.getTime();
}

function ConfirmEmailScreen(props) {
  const query = useQuery();
  const UrlCode = query.get("code");
  const storageManager = new StorageManager();

  let history = useHistory();
  function gotoClose() {
    history.push("/closeWindow");
  }
  useEffect(() => {
    addEvent("EMAIL_CONFIRMED", {}, {}, "interaction");
    async function getDataDone() {
      var confEmail = await props.api.confirmEmailNew(UrlCode);
      let project = await props.api.getProject();
      storageManager.setProject(project);
      await successMessage("Correo confirmado!");
      var flag = await props.api.getDataDone();
      var initialTime = null;
      await addEvent("End", {}, {}, "interaction");
      while (!flag) {
        if (!initialTime) initialTime = getTime();
        await sleep(2000);
        flag = await props.api.getDataDone();
        console.log("dataDone:");
        console.log(flag);
        let dif = getTime() - initialTime;
        console.log("time dif: " + dif);
        if (dif > secondsToWait * 1000) {
          flag = true;
        }
      }

      history.push(`/confirmData?code=${UrlCode}`);
    }
    getDataDone();
  }, []);
  return (
    <div className="row confirmEmail" style={{ color: "white" }}>
      <div
        className="col-12 verify"
        // style={{ padding: "1%", paddingBottom: "0%", marginTop: "10%", textAlign: "center" }}
      >
        <h2>Estamos terminando tu verificación...</h2>
        <Loader />
        {/* <LoadingOverlay
          active={true}
          // spinner={<BounceLoader />}
          spinner={true}
        />  */}
        <h2>Por favor espera 1 minuto...</h2>
      </div>
    </div>
  );
}
export default ConfirmEmailScreen;
