import React, { useEffect, useState } from "react";
import WebcamHTML from "../utils/webcam";
import { useRef } from "react";
import {
  fatalError,
  commonError,
  infoMessage,
  retryMessage,
  infoMessage2,
} from "../utils/ErrorHandler/errorHandler";
import useQuery from "../../hooks/useQuery";
import Models from "../api/api-models";
import { useHistory, useParams } from "react-router-dom";
import faceborder from "./../../assets/faceborder.png";
import glasses from "./../../assets/glasses.png";
import "./FaceAuth.scss";
import {
  CommandTextsByFace,
  CommandTextsByFaceDown,
} from "./../utils/TextsVideo/TextsAuth";
import Loader from "../utils/Loader/Loader";
import { isMobile } from "../utils/utils";

function FaceAuth(props) {
  let history = useHistory();
  let tf = props.tf;
  const query = useQuery();
  const authCode = query.get("authCode");
  const webcamRef = useRef(null);
  const [models, setModels] = useState();
  const [wcElement, setWcElement] = useState();
  const [videoRostro, setVideoRostro] = useState(false);
  const [styleFace, setStyleFace] = useState(false);
  const api = props.api;
  //Async biometrics
  // 5 cedula front, 6 cedula back, 2 cara1, 3 cara2, 4 voz
  // const [bioReqs, setBioReqs] = useState([6, 5]);
  // const [bioReqs, setBioReqs] = useState([3, 2, 6, 5]);
  const [bioReqs, setBioReqs] = useState([3]);
  //const [bioReqs, setBioReqs] = useState([4, 2]);
  const [getFailsSteps, setFailSteps] = useState([]);
  const [getFailMsg, setFailMsg] = useState([]);
  const [step, setStep] = useState(0);
  const [needsFlujo, setNeedsFlujo] = useState(false);
  const [reqsQueue, setReqsQueue] = useState([]);
  const [capture, setCapture] = useState(false);
  const [record, setRecord] = useState(false);
  const [colorCuadrito, setColorCuadrito] = useState("#ffffff6f");
  const [showCuadradito, setShowCuadradito] = useState(true);
  const [modelsLoaded, setModelsLoaded] = useState(false);

  const [seconds, setSeconds] = useState(1);

  const [loaderText, setLoaderText] = useState(["Iniciando..."]);

  const [authData, setAuthData] = useState({});

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds(seconds <= 2 ? seconds + 1 : seconds);
    }, 1000);
    // clearing interval
    return () => clearInterval(timer);
  });

  const startRecording = () => {
    setRecord(true);
  };

  useEffect(() => {
    if (!isMobile()) {
      history.push(`/qrFlowAuth?authCode=${authCode}`);
    }
  }, []);

  useEffect(() => {
    if (props.needsPase) {
      bioReqs.unshift(7);
      bioReqs.unshift(8);
    }
    if (props.needsTarjetaPropiedad) {
      bioReqs.unshift(9);
      bioReqs.unshift(10);
    }
  }, []);

  const nextAtomatic2 = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  //Advances the step
  const nextAtomatic = (ms) => {
    console.log("nextAutomatic: " + ms);
    nextAtomatic2(ms).then(() => document.getElementById("next").click());
  };

  function FlujoAlterno(typePass) {
    api.log("Step: " + step, "FlujoAlterno()");
    setCapture(true);
    infoMessage("Toca la pantalla para capturar la imagen");
    models.StartCameraCaptureMobile(typePass);
  }

  const nextBioStep = () => {
    console.log("NEXT BIO STEP");
    let localStep = reqsQueue.pop();
    console.log(localStep);
    console.log(models);
    updateStep(localStep + 1);
  };

  function addHintMsg(msg) {
    console.log("Adding Hint message!");
    console.log(msg);
    getFailMsg.push(msg);
  }

  const acceptStep = (step2) => {
    console.log("reqsQueue");
    console.log(reqsQueue);
    console.log("bioReqs");
    console.log(bioReqs);
    console.log("Accept Step: " + step2);
    if (bioReqs.indexOf(step2) != -1) bioReqs.splice(bioReqs.indexOf(step2), 1);
    console.log("reqsQueue");
    console.log(reqsQueue);
    console.log("bioReqs");
    console.log(bioReqs);
  };

  function updateStep2() {
    if (step <= 1 && step != -1) {
      console.log("BIO REQS ON START");
      console.log(bioReqs);
      console.log("QUEUE ON START");
      console.log(reqsQueue);
    }
    if (step <= 11) {
      console.log("NEXT BIO STEP");
      let localStep = reqsQueue.pop();
      console.log(localStep);

      if (bioReqs.length === 0) {
        nextAtomatic(200);
        updateStep(12);
        return;
      } else if (!localStep) {
        setLoaderText(["Estamos validando", "tu información"]);
        wcElement.stopRecording();
        console.log("");
        console.log("Reqs");
        console.log(bioReqs);
        console.log("step");
        console.log(step);
        nextAtomatic(400);
        updateStep(-1);
        setShowCuadradito(true);
        return;
      }
      updateStep(localStep);
      setLoaderText([]);
      //TEMP
    } else {
      setLoaderText([]);
      updateStep(step);
      console.log("updatePage: recolect");
      //Aca poner la siguiente RUTA!!!!
      wcElement.stopCameras().then(() => {
        // REDIRECT
        window.location.href = authData.redirectionUrl;
      });
    }
  }

  const repeatStep = (step2) => {
    console.log("reqsQueue");
    console.log(reqsQueue);
    console.log("bioReqs");
    console.log(bioReqs);
    console.log("Repeat Step: " + step2);
    reqsQueue.unshift(step2);
    console.log("reqsQueue");
    console.log(reqsQueue);
    console.log("bioReqs");
    console.log(bioReqs);
    getFailsSteps.push(step2);
  };

  const updateStep = async (stepR) => {
    console.log("updateStep");
    console.log(stepR);
    setStep(stepR);
    if (getFailsSteps.includes(stepR, 0)) {
      let msg = "";
      msg = getFailMsg.pop();
      var res = await retryMessage(msg);
    }
    try {
      switch (stepR) {
        case 0:
          console.log("paso:INICIO");
          break;
        case 1:
          models.predictFilters("filtros");
          break;

        case 2:
          setVideoRostro(true);
          setStyleFace(true);
          needsFlujo ? FlujoAlterno(2) : models.predictface("cara", 2);
          console.log("paso:ROSTRO");
          // drawCuadraditoCedula();
          break;
        case 3:
          setVideoRostro(true);
          setStyleFace(true);
          needsFlujo
            ? FlujoAlterno(3)
            : models.predictface("cara", 3, authData.regId, authCode);
          console.log("paso:IRIS");
          // drawCuadraditoCedula();
          break;
        default:
          break;
      }
    } catch (e) {
      alert("Error:" + e.toString());
    }
  };

  useEffect(() => {
    console.log("UseEffect RecolectBio");
    loadModels();
  }, []);

  useEffect(() => {
    if (modelsLoaded) updateStep2();
  }, [modelsLoaded]);

  async function loadModels() {
    initCamera();
  }
  async function initCamera() {
    var regId = "";
    var aData = await api.getAuthRequest(authCode);
    if (!aData) {
      infoMessage("Su código es incorrecto!");
      return;
    }

    setAuthData(aData);
    regId = aData.regId;
    if (reqsQueue.length === 0)
      bioReqs.forEach((val) => {
        reqsQueue.push(val);
      });
    console.log("Init Camera");
    // tf.enableProdMode();
    // await tf.ready();
    let element = new WebcamHTML(
      document.getElementById("facecam"),
      tf,
      api,
      sendToPhone
    );
    setWcElement(element);
    // element.setupBack();
    var res = true;
    // console.log(props.modelsLoader)
    var modelId = props.modelsLoader.modelId;
    var modelFace = props.modelsLoader.modelFace;
    if (!modelId || !modelFace) {
      await props.modelsLoader.loadModels(tf);
      modelId = props.modelsLoader.modelId;
      modelFace = props.modelsLoader.modelFace;
    }
    if (!models) {
      let models2 = new Models(
        "token",
        tf,
        element,
        regId,
        // setLoading,
        drawCuadraditoCedula,
        quitarCuadraditoCecdula,
        repeatStep,
        acceptStep,
        nextBioStep,
        sendToPhone,
        setColorCuadrito,
        api,
        () => {}, //ShowLoader
        modelId,
        modelFace,
        props.country,
        addHintMsg
      );
      // res = await models2.loadModel();

      setModels(models2);
      element.models = models2;
      setModelsLoaded(true);
    }
  }

  function drawCuadraditoCedula() {
    setShowCuadradito(true);
  }

  function quitarCuadraditoCecdula() {
    setShowCuadradito(false);
  }

  function sendToPhone() {}
  console.log(step);
  return (
    <div className="screen-container">
      <div className="info-container">
        <div className="text-top">
          {(step === 2 || step === 3) &&
            CommandTextsByFace({ step, colorCuadrito })}
        </div>

        {/* <div
          className="videoFace"
          style={
            step === 2 || step === 3
              ? { border: "3px solid" + colorCuadrito }
              : { display: "none" }
          }
          onClick={() => {
            console.log("ONCLICK, ONCLICK NULL");
          }}
        ></div> */}
        <div
          className="camera-border-auth"
          style={
            step === 2 || step === 3
              ? { borderColor: colorCuadrito }
              : { display: "none" }
          }
        >
          <video
            autoPlay
            playsInline
            id="facecam"
            className={videoRostro === true ? "camera-video" : "nada2"}
            audio="false"
            ref={webcamRef}
          />
        </div>
        <div className="text-bottom">
          {(step === 2 || step === 3) &&
            CommandTextsByFaceDown({ step, colorCuadrito })}
        </div>
      </div>
      <div style={loaderText.length !== 0 ? {} : { display: "none" }}>
        <Loader txts={loaderText} />
      </div>

      <button hidden={true} id="next" onClick={() => updateStep2()}></button>
    </div>
  );
}
export default FaceAuth;
