import React, { useEffect, useState } from "react";
import { addEvent } from "../api/Events/EventsApi";
import { useHistory } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import ClipLoader from "react-spinners/ClipLoader";
import StorageManager from "../utils/StorageManager";
import "./ConfirmData.scss";
import { TextFieldNew } from "../utils/TextField/TextFieldNew";
import { DateTextField } from "../utils/TextField/DateTextField";
import { Button4, Button5 } from "./../utils/Buttons/Buttons";
import TopAppBar from "../AppBar/TopAppBar";
import BottomAppBar from "../AppBar/BottomAppBar";

export default function ConfirmData({ api }) {
  let history = useHistory();
  const query = useQuery();
  const UrlCode = query.get("code");

  // Cedula info
  const [nombres, setNombres] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [documentNumber, setDocumentNumber] = useState("");
  const [expeditionDate, setExpeditionDate] = useState(new Date());
  const [expeditionDateString, setExpeditionDateString] = useState(
    getStringFromDate(new Date())
  );
  const [expeditionPlace, setExpeditionPlace] = useState("");
  const [birthDate, setBirthDate] = useState(new Date());
  const [birthDateString, setBirthDateString] = useState(
    getStringFromDate(new Date())
  );
  const [birthPlace, setBirthPlace] = useState("");
  const [birthState, setBirthState] = useState("");
  const [bloodType, setBloodType] = useState("");
  const [sexo, setSexo] = useState("");
  // Pasaporte info
  const [expirationDate, setExpirationDate] = useState(new Date());
  const [expirationDateString, setExpirationDateString] = useState("");

  const [loadedData, setLoadedData] = useState(false);
  const [changedData, setChangedData] = useState(false);
  const [canChangeData, setCanChangeData] = useState(false);
  const [isPassport, setIsPassport] = useState(false);

  const [showIndicatorBottom, setShowIndicatorBottom] = useState(false);
  const [showIndicatorTop, setShowIndicatorTop] = useState(false);
  const [bottomAppBarHeight, setBottomAppBarHeight] = useState("");

  const storageManager = new StorageManager();

  function bloodTypeErrorText() {
    if (bloodType.length === 0) {
      return "Ingrese el tipo de sangre";
    } else if (!bloodType.includes("-") && !bloodType.includes("+")) {
      return "Ingrese el RH (+ o -)";
    } else {
      return undefined;
    }
  }

  function buttonDisabled() {
    if (!isPassport) {
      if (bloodTypeErrorText() !== undefined) {
        return true;
      }
      if (nombres.length === 0) {
        return true;
      }
      if (apellidos.length === 0) {
        return true;
      }
      if (documentNumber.length < 5) {
        return true;
      }
      if (expeditionPlace.length === 0) {
        return true;
      }
      if (birthPlace.length === 0) {
        return true;
      }
      if (birthState.length === 0) {
        return true;
      }
      if (bloodType.length === 0) {
        return true;
      }
      if (sexo.length === 0) {
        return true;
      } else {
        return false;
      }
    } else {
      if (nombres.length === 0) {
        return true;
      }
      if (apellidos.length === 0) {
        return true;
      }
      if (documentNumber.length < 5) {
        return true;
      }
      if (expeditionDateString.length < 14) {
        return true;
      }
      if (expirationDateString.length < 14) {
        return true;
      }
      if (birthDateString.length < 14) {
        return true;
      }
      if (birthPlace.length === 0) {
        return true;
      }
      if (sexo.length === 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  async function finishFlow() {
    setLoadedData(false);

    if (changedData) {
      if (!isPassport) {
        let data = await api.confirmData({
          firstName: nombres.toUpperCase(),
          lastName: apellidos.toUpperCase(),
          documentType: documentType.toUpperCase(),
          documentNumber: documentNumber,
          expeditionDate: expeditionDateString.toUpperCase(),
          expeditionCity: expeditionPlace.toUpperCase(),
          birthDate: birthDateString.toUpperCase(),
          gender: sexo.toUpperCase(),
          birthPlace: birthPlace.toUpperCase(),
          bloodType: bloodType.toUpperCase(),
          birthState: birthState.toUpperCase(),
        });

        if (data) {
          history.push(`/identidadDigital?code=${UrlCode}`);
        }
      } else {
        let data = await api.confirmData({
          firstName: nombres.toUpperCase(),
          lastName: apellidos.toUpperCase(),
          documentType: documentType.toUpperCase(),
          documentNumber: documentNumber.toUpperCase(),
          expeditionDate: expeditionDateString.toUpperCase(),
          expirationDate: expirationDateString.toUpperCase(),
          birthDate: birthDateString.toUpperCase(),
          birthPlace: birthPlace.toUpperCase(),
          gender: sexo.toUpperCase(),
        });
        if (data) {
          await history.push(`/identidadDigital?code=${UrlCode}`);
        }
      }
    } else {
      await history.push(`/identidadDigital?code=${UrlCode}`);
    }
  }

  async function notCorrect() {
    setLoadedData(false);
    let project = storageManager.getProject();

    let currentTries = storageManager.getGenericString("confirmDataTries");
    // convert to int
    currentTries = parseInt(currentTries);
    if (!currentTries) {
      currentTries = 0;
    }
    if (project.extraConfig.confirmDataTries) {
      if (currentTries < project.extraConfig.confirmDataTries) {
        currentTries++;
        storageManager.setGenericString(
          currentTries.toString(),
          "confirmDataTries"
        );
        history.push(`/biometrics?code=${UrlCode}&confirmData=true`);
      } else {
        setCanChangeData(true);
      }
    } else {
      setCanChangeData(true);
    }
    setLoadedData(true);
  }

  function getDateFromString(dateString) {
    if (dateString === null || dateString === undefined || dateString === "") {
      return new Date();
    }
    const monthAbbreviations = {
      ENE: 0,
      FEB: 1,
      MAR: 2,
      ABR: 3,
      MAY: 4,
      JUN: 5,
      JUL: 6,
      AGO: 7,
      SEP: 8,
      OCT: 9,
      NOV: 10,
      DIC: 11,
    };

    const [day, monthStr, year] = dateString.split("-");

    const month = monthAbbreviations[monthStr];
    const formattedDate = new Date(year, month, day);
    if (formattedDate.toString() === "Invalid Date") return new Date();
    return formattedDate;
  }

  function getStringFromDate(date) {
    if (date === null || date === undefined) return "";
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date
      .toLocaleDateString("es-ES", options)
      .toUpperCase()
      .replace(/ /g, "-");

    return formattedDate;
  }

  async function getData() {
    let currentTries = storageManager.getGenericString("confirmDataTries");

    if (currentTries) {
      await new Promise((r) => setTimeout(r, 7000));
    }
    let data = await api.getRegistrationData();
    if (data && data.userIdType === "CEDULA") {
      let documentData = data.userDocument;
      setIsPassport(false);
      if (documentData) {
        if (documentData.firstName) {
          setNombres(documentData.firstName);
        }
        if (documentData.lastName) {
          setApellidos(documentData.lastName);
        }
        if (documentData.type === "CEDULA") {
          setDocumentType("Cedula de Ciudadania");
        }
        if (documentData.documentNumber) {
          setDocumentNumber(documentData.documentNumber);
        }
        if (documentData.expeditionDate) {
          setExpeditionDateString(documentData.expeditionDate.toUpperCase());
          setExpeditionDate(
            getDateFromString(documentData.expeditionDate.toUpperCase())
          );
        }

        if (documentData.expeditionCity) {
          setExpeditionPlace(documentData.expeditionCity.toUpperCase());
        }
        if (documentData.birthDate) {
          setBirthDate(getDateFromString(documentData.birthDate.toUpperCase()));
          setBirthDateString(documentData.birthDate.toUpperCase());
        }
        if (documentData.birthPlace) {
          setBirthPlace(documentData.birthPlace.toUpperCase());
        }
        if (documentData.birthState) {
          setBirthState(documentData.birthState.toUpperCase());
        }
        if (documentData.bloodType) {
          setBloodType(documentData.bloodType.toUpperCase());
        }
        if (documentData.gender) {
          setSexo(documentData.gender.toUpperCase());
        }
        setLoadedData(true);
      } else {
        history.push(`/biometrics?code=${UrlCode}&confirmData=true`);
      }
    } else if (data && data.userIdType === "PASAPORTE") {
      setIsPassport(true);
      let documentData = data.userDocument;
      if (documentData) {
        if (documentData.firstName) {
          setNombres(documentData.firstName);
        }
        if (documentData.lastName) {
          setApellidos(documentData.lastName);
        }
        if (documentData.expeditionDate) {
          setExpeditionDateString(documentData.expeditionDate.toUpperCase());
        }
        if (documentData.expirationDate) {
          setExpirationDateString(documentData.expirationDate.toUpperCase());
        }
        if (documentData.documentNumber) {
          setDocumentNumber(documentData.documentNumber);
        }
        if (documentData.birthDate) {
          setBirthDateString(documentData.birthDate.toUpperCase());
        }
        if (documentData.birthPlace) {
          setBirthPlace(documentData.birthPlace.toUpperCase());
        }
        if (documentData.gender) {
          setSexo(documentData.gender.toUpperCase());
        }
        setLoadedData(true);
      } else {
        history.push(`/biometrics?code=${UrlCode}&confirmData=true`);
      }
    } else {
      history.push(`/identidadDigital?code=${UrlCode}`);
    }
  }
  function gotoClose() {
    history.push("/closeWindow");
  }
  useEffect(() => {
    let project = storageManager.getProject();
    // if (project) {
    //   if (!project.extraConfig.canConfirm) {
    //     //finish flow
    //     api.final(gotoClose);
    //   }
    // }
    if(bottomAppBarHeight !== "")
    {
        var tamanio = 90 - bottomAppBarHeight.split("%")[0];
        document.getElementById("contenedorConfirmData").style.height = tamanio + "vh";
    }
    if(bottomAppBarHeight === ""){
      getData();
    }
    return () => {};
  }, [bottomAppBarHeight]);

  function botonSi() {
    let project = storageManager.getProject();
    let currentTries = storageManager.getGenericString("confirmDataTries");
    let confirmDataTries = project.extraConfig.confirmDataTries;
    addEvent(
      "CONFIRM_DATA_YES",
      {
        tries: currentTries,
        maxTries: confirmDataTries,
      },
      {},
      "interaction"
    );
    finishFlow();
  }

  function botonNo() {
    let project = storageManager.getProject();
    let currentTries = storageManager.getGenericString("confirmDataTries");
    let confirmDataTries = project.extraConfig.confirmDataTries;
    addEvent(
      "CONFIRM_DATA_NO",
      {
        tries: currentTries,
        maxTries: confirmDataTries,
      },
      {},
      "interaction"
    );
    notCorrect();
  }

  function botonConfirmarDatos() {
    if (buttonDisabled()) return;
    let project = storageManager.getProject();
    let currentTries = storageManager.getGenericString("confirmDataTries");
    let confirmDataTries = project.extraConfig.confirmDataTries;
    addEvent(
      "CONFIRM_DATA_CONFIRM",
      {
        tries: currentTries,
        maxTries: confirmDataTries,
      },
      {},
      "interaction"
    );
    finishFlow();
  }

  function handleCallback(bottomAppBar){
    setBottomAppBarHeight(bottomAppBar);
  }

  return (
    <div
      id="contenedorConfirmData"
      className="flex-col align-center justify-center"
      style={{
        height: !loadedData ? "100vh" : "82vh",
        width: !loadedData ? "100vw" : "100%",
        padding: "25px 0 25px 0",
        overflow: "scroll",
      }}
    >
      <TopAppBar />
      {!loadedData ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ClipLoader color="#fff" size={"120px"} />{" "}
          <h6
            className="font-weight-700 uppercase font-white text-center"
            style={{ fontSize: "20px", width: "45%", margin: "22px 0 0 0" }}
          >
            Procesando información
          </h6>
        </div>
      ) : (
        <>
          {isPassport ? (
            <>
              {" "}
              <h6
                className="font-weight-800 uppercase font-white text-center"
                style={{ fontSize: "20px", width: "60%", margin: "0 0 30px 0" }}
              >
                Es tiempo de confirmar tus datos
              </h6>
              <TextFieldNew
                disabled={!canChangeData}
                label="Nombres"
                type="text"
                value={nombres}
                onChange={(e) => {
                  setChangedData(true);
                  setNombres(e.target.value);
                }}
                errorText={nombres.length < 1 ? "Ingrese  nombre" : undefined}
              />
              <TextFieldNew
                disabled={!canChangeData}
                label="Apellidos"
                type="text"
                value={apellidos}
                onChange={(e) => {
                  setChangedData(true);
                  setApellidos(e.target.value);
                }}
                errorText={
                  apellidos.length < 1 ? "Ingrese apellidos" : undefined
                }
              />{" "}
              <TextFieldNew
                disabled={!canChangeData}
                label="No. Pasaporte"
                type={isPassport ? "text" : "number"}
                value={documentNumber}
                onChange={(e) => {
                  if (e.target.value.length < 9) {
                    setChangedData(true);
                    setDocumentNumber(e.target.value);
                  }
                }}
                errorText={
                  documentNumber.length < 8
                    ? "El número de pasaporte debe tener 8 dígitos"
                    : undefined
                }
              />
              <TextFieldNew
                disabled={!canChangeData}
                label="Fecha de nacimiento"
                type="text"
                value={birthDateString}
                onChange={(e) => {
                  if (e.target.value.length < 17) {
                    setChangedData(true);
                    setBirthDateString(e.target.value);
                  }
                }}
                helpText={
                  canChangeData
                    ? "Tal como aparece en el documento Ej: 12 NOV/NOV 1980"
                    : undefined
                }
                errorText={
                  birthDateString.length < 14
                    ? "La fecha de nacimiento debe tener minimo 14 caracteres"
                    : undefined
                }
              />
              <TextFieldNew
                disabled={!canChangeData}
                label="Lugar de nacimiento"
                type="text"
                value={birthPlace}
                onChange={(e) => {
                  setChangedData(true);
                  setBirthPlace(e.target.value);
                }}
                helpText={
                  canChangeData
                    ? "Tal como aparece en el documento Ej: BOGOTA COL"
                    : undefined
                }
                errorText={
                  birthPlace.length < 1
                    ? "Ingrese lugar de nacimiento"
                    : undefined
                }
              />
              <TextFieldNew
                disabled={!canChangeData}
                label="Fecha de expedición"
                type="text"
                value={expeditionDateString}
                onChange={(e) => {
                  if (e.target.value.length < 17) {
                    setChangedData(true);
                    setExpeditionDateString(e.target.value);
                  }
                }}
                helpText={
                  canChangeData
                    ? "Tal como aparece en el documento Ej: 12 NOV/NOV 2017"
                    : undefined
                }
                errorText={
                  expeditionDateString.length < 14
                    ? "La fecha de expedición debe tener minimo 14 caracteres"
                    : undefined
                }
              />
              <TextFieldNew
                disabled={!canChangeData}
                label="Fecha de vencimiento"
                type="text"
                value={expirationDateString}
                onChange={(e) => {
                  if (e.target.value.length < 17) {
                    setChangedData(true);
                    setExpirationDateString(e.target.value);
                  }
                }}
                helpText={
                  canChangeData
                    ? "Tal como aparece en el documento Ej: 12 NOV/NOV 2027"
                    : undefined
                }
                errorText={
                  expirationDateString.length < 14
                    ? "La fecha de vencimiento debe tener minimo 14 caracteres"
                    : undefined
                }
              />
              <TextFieldNew
                disabled={!canChangeData}
                label="Sexo"
                type="text"
                value={sexo}
                onChange={(e) => {
                  if (e.target.value.length < 2) {
                    if (
                      e.target.value.toUpperCase().includes("M") ||
                      e.target.value.toUpperCase().includes("F") ||
                      e.target.value.toUpperCase().includes("X")
                    ) {
                      setChangedData(true);
                      setSexo(e.target.value);
                    } else if (e.target.value === "") {
                      setChangedData(true);
                      setSexo(e.target.value);
                    }
                  }
                }}
                errorText={sexo.length < 1 ? "Ingrese sexo" : undefined}
              />
            </>
          ) : (
            <>
              <div
                style={{
                  position: "fixed",
                  bottom: 0,
                  right: "20%",
                  transform: "translateX(-50%)",
                  display: showIndicatorBottom ? "block" : "none",
                }}
              >
                <span style={{ fontSize: "2rem", color: "black" }}>▼</span>
              </div>
              <h6
                className="font-weight-800 uppercase font-white text-center"
                style={{
                  fontSize: "20px",
                  width: "60%",
                  margin: "0 0 30px 0",
                }}
              >
                Es tiempo de confirmar tus datos
              </h6>
              <p
                style={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#EBFF00",
                  width: "234px",
                  margin: "2rem 0",
                }}
              >
                Revisa tu documento cuidadosamente y confirma si concuerda con
                los siguientes datos
              </p>
              <TextFieldNew
                disabled={!canChangeData}
                label="Nombres"
                type="text"
                value={nombres}
                onChange={(e) => {
                  setChangedData(true);
                  setNombres(e.target.value);
                }}
                errorText={nombres.length < 1 ? "Ingrese  nombre" : undefined}
              />
              <TextFieldNew
                disabled={!canChangeData}
                label="Apellidos"
                type="text"
                value={apellidos}
                onChange={(e) => {
                  setChangedData(true);
                  setApellidos(e.target.value);
                }}
                errorText={
                  apellidos.length < 1 ? "Ingrese apellidos" : undefined
                }
              />{" "}
              <TextFieldNew
                disabled={!canChangeData}
                label="Tipo de Documento"
                type={"text"}
                value={documentType}
                onChange={(e) => {
                  setChangedData(true);
                  setDocumentNumber(e.target.value);
                }}
                errorText={
                  documentType === "CEDULA DE CIUDADANIA" ||
                  documentType === "PASAPORTE"
                    ? "El tipo de documento debe ser CEDULA DE CIUDADANIA o PASAPORTE"
                    : undefined
                }
              />
              <TextFieldNew
                disabled={!canChangeData}
                label="No. Documento"
                type={isPassport ? "text" : "number"}
                value={documentNumber}
                onChange={(e) => {
                  let onlyDigitsReg = /^\d+$/;
                  if (!isPassport) {
                    if (
                      onlyDigitsReg.test(e.target.value) ||
                      e.target.value.length === 0
                    ) {
                      if (e.target.value.length < 11) {
                        setChangedData(true);
                        setDocumentNumber(e.target.value);
                      }
                    }
                  } else {
                    setChangedData(true);
                    setDocumentNumber(e.target.value);
                  }
                }}
                errorText={
                  documentNumber.length < 5
                    ? "El número de documento debe tener minimo 5 dígitos"
                    : undefined
                }
              />
              <DateTextField
                disabled={!canChangeData}
                label={"Fecha de nacimiento"}
                selectedDate={birthDate}
                maxDate={new Date()}
                setSelectedDate={(date) => {
                  setBirthDate(date);
                  setBirthDateString(getStringFromDate(date));
                  setChangedData(true);
                }}
              />
              <DateTextField
                disabled={!canChangeData}
                label={"Fecha de expedición"}
                selectedDate={expeditionDate}
                maxDate={new Date()}
                setSelectedDate={(date) => {
                  setExpeditionDate(date);
                  setExpeditionDateString(getStringFromDate(date));
                  setChangedData(true);
                }}
              />
              <TextFieldNew
                disabled={!canChangeData}
                label="Ciudad de nacimiento"
                type="text"
                value={birthPlace}
                onChange={(e) => {
                  const regex = /^([A-Za-zÁÉÍÓÚáéíóúñÑ]+\.?\s?)+$/;
                  if (
                    regex.test(e.target.value) ||
                    e.target.value.length === 0
                  ) {
                    setChangedData(true);
                    setBirthPlace(e.target.value);
                  }
                }}
                helpText={
                  canChangeData
                    ? "Tal como aparece en el documento Ejemplo: Bogotá D.C."
                    : undefined
                }
                errorText={
                  birthPlace.length < 1
                    ? "Ingrese ciudad de nacimiento"
                    : undefined
                }
              />
              <TextFieldNew
                disabled={!canChangeData}
                label="Sexo"
                type="text"
                value={sexo}
                onChange={(e) => {
                  if (
                    e.target.value.toUpperCase().startsWith("M") ||
                    e.target.value.toUpperCase().startsWith("F") ||
                    e.target.value.toUpperCase().startsWith("T") ||
                    e.target.value.toUpperCase().startsWith("N") ||
                    e.target.value.length === 0
                  ) {
                    if (e.target.value.length === 1) {
                      setChangedData(true);
                      setSexo(e.target.value);
                    } else if (e.target.value.length === 0) {
                      setChangedData(true);
                      setSexo(e.target.value);
                    } else if (e.target.value.length === 2) {
                      if (e.target.value.toUpperCase() === "NB") {
                        setChangedData(true);
                        setSexo(e.target.value);
                      }
                    }
                  }
                }}
                errorText={sexo.length < 1 ? "Ingrese sexo" : undefined}
              />
            </>
          )}
          {!canChangeData ? (
            <>
              <h6
                className="font-weight-700 font-white text-center"
                style={{
                  fontSize: "20px",
                  width: "50%",
                  margin: "1rem 0 2rem 0",
                }}
              >
                ¿Todos tus datos son correctos?
              </h6>
              <BottomAppBar
                parentCallback={handleCallback}
                fontSize={global.fontSize}
                botonNoHabilitado={true}
                botonConfirmarHabilitado={true}
                botonOptionsHabilitado={true}
                botonNoClick={botonNo}
                botonConfirmarClick={botonSi}
              />
            </>
          ) : (
            <>
              <h6
                className="font-weight-600 font-white text-center"
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  width: "70%",
                  margin: "20px 0 30px 0",
                  color: "#EBFF00",
                }}
              >
                Edita correctamente los datos incorrectos y confirmalos
              </h6>
              <BottomAppBar
                parentCallback={handleCallback}
                fontSize={global.fontSize}
                botonNoHabilitado={false}
                botonConfirmarHabilitado={true}
                botonOptionsHabilitado={true}
                botonConfirmarClick={botonConfirmarDatos}
              />
            </>
          )}
        </>
      )}
    </div>
  );
}
