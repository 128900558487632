import "./IdentidadDigital.scss";
import React, { useEffect, useState } from "react";
import TopAppBar from "../AppBar/TopAppBar";
import BottomAppBar from "../AppBar/BottomAppBar";
import { ReactComponent as LEFT } from "../../assets/v10/Polygon 7.svg";
import { ReactComponent as RIGHT } from "../../assets/v10/Polygon 8.svg";
import { useHistory } from "react-router-dom";
import { LoadingOverlayCustom } from "../utils/Loader/LoadingOverlayCustom";

export default function IdentidadDigital({api}){
    const [esSelectProfile, setEsSelectProfile] = useState(false);
    const [imagenesTomadas, setImagenesTomadas] = useState([]);
    const [imagenSeleccionada, setImagenSeleccionada] = useState("");
    const [indiceImagenSelec, setIndiceImagenSelec] = useState(0);
    const [imagenesAvatar, setImagenesAvatar] = useState(["Blanco y Negro", "Normal", "Sepia", "invert"]);
    const [textoImagenAvatar, setTextoImagenAvatar] = useState("Blanco y Negro");
    const [indiceImagenAvatar, setIndiceImagenAvatar] = useState(0);
    const [seleccionoImagenArriba, setSeleccionoImagenArriba] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [estilo, setEstilo] = useState("")
    const [nombres, setNombres] = useState("");
    const [apellidos, setApellidos] = useState("");
    const [documentType, setDocumentType] = useState("");
    const [documentNumber, setDocumentNumber] = useState("");
    const [bottomAppBarHeight, setBottomAppBarHeight] = useState("");
    let history = useHistory();

    useEffect(()=>{
        if(!esSelectProfile){
            getImages();
        }        
        if(esSelectProfile){
            document.getElementById("imagenConEfecto").style.filter = estilo;
            getData();
        }
        if(bottomAppBarHeight !== "")
        {
            var tamanio = 100 - bottomAppBarHeight.split("%")[0];
            document.getElementById("contenedorIdentidaDig").style.height = tamanio + "vh";
        }
    }, [esSelectProfile])

    async function getImages(){
        let resp = await api.getFaceImages();
        setImagenesTomadas(resp.facePics);
        setImagenSeleccionada(`data:image/png;base64, ${resp.facePics[indiceImagenSelec].data}`)
        document.getElementById("botonAvatar").style.filter = "grayscale(100%)";
    }

    function beforeProf() {
        if (indiceImagenSelec - 1 >= 0) {
        let nuevaImg = imagenesTomadas[indiceImagenSelec - 1]["data"];
        setImagenSeleccionada(`data:image/png;base64, ${nuevaImg}`);
        setIndiceImagenSelec(indiceImagenSelec - 1);
        } else {
        let nuevaImg = imagenesTomadas[imagenesTomadas.length - 1]["data"];
        setImagenSeleccionada(`data:image/png;base64, ${nuevaImg}`);
        setIndiceImagenSelec(imagenesTomadas.length - 1);
        }
    }

    function nextProf() {
        if (indiceImagenSelec + 1 < imagenesTomadas.length) {
        let nuevaImg = imagenesTomadas[indiceImagenSelec + 1]["data"];
        setImagenSeleccionada(`data:image/png;base64, ${nuevaImg}`);
        setIndiceImagenSelec(indiceImagenSelec + 1);
        } else {
        let nuevaImg = imagenesTomadas[0]["data"];
        setImagenSeleccionada(`data:image/png;base64, ${nuevaImg}`);
        setIndiceImagenSelec(0);
        }
    }

    function next() {
        var textoImagen = "";
        if (indiceImagenAvatar + 1 < imagenesAvatar.length) {
        setIndiceImagenAvatar(indiceImagenAvatar + 1);
        setTextoImagenAvatar(imagenesAvatar[indiceImagenAvatar + 1]);
        textoImagen = imagenesAvatar[indiceImagenAvatar + 1];
        } else {
        setIndiceImagenAvatar(0);
        setTextoImagenAvatar(imagenesAvatar[0]);
        textoImagen = imagenesAvatar[0];
        }
        if (textoImagen === imagenesAvatar[0]) {
        document.getElementById("botonAvatar").style.filter = "grayscale(100%)";
        }
        if (textoImagen === imagenesAvatar[1]) {
        document.getElementById("botonAvatar").style.filter = "grayscale(0%)";
        }
        if (textoImagen === imagenesAvatar[2]) {
        document.getElementById("botonAvatar").style.filter = "sepia(1)";
        }
        if (textoImagen === imagenesAvatar[3]) {
        document.getElementById("botonAvatar").style.filter = "invert(.9)";
        }
    }

    function before() {
        var textoImagen = "";
        if (indiceImagenAvatar - 1 >= 0) {
          setIndiceImagenAvatar(indiceImagenAvatar - 1);
          setTextoImagenAvatar(imagenesAvatar[indiceImagenAvatar - 1]);
          textoImagen = imagenesAvatar[indiceImagenAvatar - 1];
        } else {
          setIndiceImagenAvatar(imagenesAvatar.length - 1);
          setTextoImagenAvatar(imagenesAvatar[imagenesAvatar.length - 1]);
          textoImagen = imagenesAvatar[imagenesAvatar.length - 1];
        }
        if (textoImagen === imagenesAvatar[0]) {
          document.getElementById("botonAvatar").style.filter = "grayscale(100%)";
        }
        if (textoImagen === imagenesAvatar[1]) {
          document.getElementById("botonAvatar").style.filter = "grayscale(0%)";
        }
        if (textoImagen === imagenesAvatar[2]) {
          document.getElementById("botonAvatar").style.filter = "sepia(1)";
        }
        if (textoImagen === imagenesAvatar[3]) {
          document.getElementById("botonAvatar").style.filter = "invert(.9)";
        }
    }

    function seleccionarImagen() {
        if (!seleccionoImagenArriba) {
          setSeleccionoImagenArriba(true);
        } else {
          setEstilo(document.getElementById("botonAvatar").style.filter);
          setEsSelectProfile(true);
        }
    }
    
    function anterior() {
        if (esSelectProfile) {
          setEsSelectProfile(false);
        } else {
          setSeleccionoImagenArriba(false);
        }
    }

    function gotoClose() {
        history.push("/closeWindow");
    }
    
    async function confirmar() {
        setIsLoading(true);
        await api.final(gotoClose);
        setIsLoading(false);
    }

    async function getData() {
        let data = await api.getRegistrationData();
        if (data && data.userIdType === "CEDULA") {
            let documentData = data.userDocument;
            console.log(documentData)
            if (documentData) {
                if (documentData.firstName) {
                    setNombres(documentData.firstName);
                    }
                    if (documentData.lastName) {
                    setApellidos(documentData.lastName);
                    }
                    if (documentData.type === "CEDULA"){
                    setDocumentType("Cedula de Ciudadania");
                    }
                    if (documentData.documentNumber) {
                    setDocumentNumber(documentData.documentNumber);
                    }
            }
        }
    }

    function handleCallback(bottomAppBar){
        setBottomAppBarHeight(bottomAppBar);
    }

    if (isLoading) {
        return (
          <LoadingOverlayCustom
            loading={isLoading}
            children={[
              {
                textBottom: "Un momento por favor",
                textTop: "Estamos procesando tu información",
              },
            ]}
          />
        );
    } else {
        return(
            <div id="contenedorIdentidaDig" className="contenedorIdentidaDig">
                <TopAppBar/>
                {!esSelectProfile && (
                    <>
                        <p className="titulo">Es momento de crear<br/>tu identidad digital</p>
                        <p className="tituloSeleccion">Selecciona <br/> tu mejor captura</p>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            {!seleccionoImagenArriba && (
                                <div style={{width: "15vw", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <button onClick={beforeProf} style={{backgroundColor: "transparent", border: "none"}}><LEFT/></button>
                                </div>
                            )}                        
                            <img style={{width: "120px", height: "120px", borderRadius: "30px", objectFit: "cover"}} src={imagenSeleccionada} alt="imagenRostro"/>
                            {!seleccionoImagenArriba && (
                                <div style={{width: "15vw", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <button onClick={nextProf} style={{backgroundColor: "transparent", border: "none"}}><RIGHT/></button>
                                </div>
                            )}   
                        </div>
                        <p className="tituloAhora">AHORA</p>
                        <p className="tituloSeleccionaAvatar">Selecciona <br/> el estilo de tu avatar</p>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            {seleccionoImagenArriba && (
                                <div style={{width: "15vw", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <button onClick={before} style={{backgroundColor: "transparent", border: "none"}}><LEFT/></button>
                                </div>
                            )}
                            <button id="botonAvatar" style={{background: "transparent", border: "none"}}><img style={{width: "180px", height: "180px", borderRadius: "30px", objectFit: "cover"}} src={imagenSeleccionada} alt="imagenSeleccionada"/></button>
                            {seleccionoImagenArriba && (
                                <div style={{width: "15vw", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <button onClick={next} style={{backgroundColor: "transparent", border: "none"}}><RIGHT/></button>
                                </div>
                            )}
                        </div>
                        <h4 style={{color: "white", margin: "1rem 0", fontWeight: "lighter"}}>{textoImagenAvatar}</h4>
                        <BottomAppBar parentCallback={handleCallback} botonAnteriorHabilitado={true} botonSiguienteHabilitado={true} botonSeleccionarHabilitado={true} botonOptionsHabilitado={true} botonSiguienteClick={seleccionarImagen} botonSeleccionarClick={seleccionarImagen} botonAnteriorClick={anterior}/>
                    </>
                )}
                {esSelectProfile && (
                    <>
                        <h4 style={{color: "white", margin: "4rem 0 1rem 0", fontFamily: 'Open Sans', fontStyle: "normal", fontWeight: "bolder", fontSize: "20px"}}>Asi se verá tu identidad digital</h4>
                        <img id="imagenConEfecto" style={{width: "250px", height: "250px", borderRadius: "30px", objectFit: "cover"}} src={imagenSeleccionada} alt="ros031"/>
                        <input className="entrada" style={{backgroundColor: "transparent", color: "white", marginTop: "2rem", borderColor: "white", borderRadius: "10px", height: "2rem", width: "80%"}} placeholder="NOMBRES" type="text" value={nombres}/>
                        <input className="entrada" style={{backgroundColor: "transparent", color: "white", marginTop: "2rem", borderColor: "white", borderRadius: "10px", height: "2rem", width: "80%"}} placeholder="APELLIDOS" type="text" value={apellidos}/>
                        <input className="entrada" style={{backgroundColor: "transparent", color: "white", marginTop: "2rem", borderColor: "white", borderRadius: "10px", height: "2rem", width: "80%"}} placeholder="TIPO DE DOCUMENTO" type="text" value={documentType}/>
                        <input className="entrada" style={{backgroundColor: "transparent", color: "white", marginTop: "2rem", borderColor: "white", borderRadius: "10px", height: "2rem", width: "80%"}} placeholder="No. DE DOCUMENTO" type="number" value={documentNumber}/>
                        <BottomAppBar parentCallback={handleCallback} botonConfirmarHabilitado={true} botonSiguienteHabilitado={true} botonAnteriorHabilitado={true} botonOptionsHabilitado={true} botonSiguienteClick={confirmar} botonConfirmarClick={confirmar} botonAnteriorClick={anterior}/>
                    </>
                )}
            </div>
        )
    }    
}