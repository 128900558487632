import React, { useEffect, useState } from "react";
import "./BottomAppBar.scss";
import PAUSE from "../../assets/v10/VectorPause.svg";
import ANTERIOR from "../../assets/v10/VectorBack.svg";
import SIGUIENTE from "../../assets/v10/VectorForward.svg";
import CONFIRMAR from "../../assets/v10/VectorCofirm.svg";
import CONFIRMARBLUE from "../../assets/v10/VectorConfirmBlue.svg";
import OPTIONS from "../../assets/v10/VectorOptions.svg";
import RECHAZAR from "../../assets/v10/VectorCancel.svg";
import NO from "../../assets/v10/VectorNo.svg";
import SELECCIONAR from "../../assets/v10/VectorSelect.svg";
import REENVIARCOD from "../../assets/v10/VectorResendCode.svg";
import MODIFICARNUM from "../../assets/v10/VectorModify.svg";
import REENVIARCORR from "../../assets/v10/VectorResendEmail.svg";

declare global{
    var fontSize: string;
}

export default function BottomAppBar(props: any){
    const [botonPausaHabilitado, setBotonPausaHabilitado] = useState(false);
    const [botonAnteriorHabilitado, setBotonAnteriorHabilitado] = useState(false);
    const [botonSiguienteHabilitado, setBotonSiguienteHabilitado] = useState(false);
    const [botonConfirmarHabilitado, setBotonConfirmarHabilitado] = useState(false);
    const [botonOptionsHabilitado, setBotonOptionsHabilitado] = useState(false);
    const [botonRechazarHabilitado, setBotonRechazarHabilitado] = useState(false);
    const [botonNoHabilitado, setBotonNoHabilitado] = useState(false);
    const [botonSeleccionarHabilitado, setBotonSeleccionarHabilitado] = useState(false);
    const [botonReenviarCodigoHabilitado, setBotonReenviarCodigoHabilitado] = useState(false);
    const [botonModificarNumeroHabilitado, setBotonModificarNumeroHabilitado] = useState(false);
    const [botonModificarCorreoHabilitado, setBotonModificarCorreoHabilitado] = useState(false);    
    const [botonReenviarCorreoHabilitado, setBotonReenviarCorreoHabilitado] = useState(false);
    const [accionExpandida, setAccionExpandida] = useState(false);
    const [botonConfirmarColor, setBotonConfirmarColor] = useState("");
    const [fontSize, setFontSize] = useState(10);
    const [primeraVez, setPrimeraVez] = useState(true);
    const [llamarAtenea, setLlamarAtenea] = useState(false);
    
    useEffect(()=>{
        if(props.botonAnteriorHabilitado){
            setBotonAnteriorHabilitado(props.botonAnteriorHabilitado);
        }
        if(props.botonSiguienteHabilitado){
            setBotonSiguienteHabilitado(props.botonSiguienteHabilitado);
        }
        if(props.botonConfirmarHabilitado){
            setBotonConfirmarHabilitado(props.botonConfirmarHabilitado);
        }
        if(props.botonOptionsHabilitado){
            setBotonOptionsHabilitado(props.botonOptionsHabilitado);
        }
        if(props.botonRechazarHabilitado){
            setBotonRechazarHabilitado(props.botonRechazarHabilitado);
        }
        if(props.colorConfirmar){
            setBotonConfirmarColor(props.colorConfirmar);
        }
        if(props.botonReenviarCodigoHabilitado){
            setBotonReenviarCodigoHabilitado(props.botonReenviarCodigoHabilitado)
        }
        if(props.botonModificarNumeroHabilitado){
            setBotonModificarNumeroHabilitado(props.botonModificarNumeroHabilitado)
        }
        if(props.botonModificarCorreoHabilitado){
            setBotonModificarCorreoHabilitado(props.botonModificarCorreoHabilitado)
        }
        if(props.botonReenviarCorreoHabilitado){
            setBotonReenviarCorreoHabilitado(props.botonReenviarCorreoHabilitado);
        }
        if(props.botonNoHabilitado){
            setBotonNoHabilitado(props.botonNoHabilitado);
        }
        if(props.botonSeleccionarHabilitado){
            setBotonSeleccionarHabilitado(props.botonSeleccionarHabilitado);
        }
        if(props.llamarAtenea){
            setLlamarAtenea(props.llamarAtenea)
        }
        var botones = Array.from(document.getElementsByClassName("boton") as HTMLCollectionOf<HTMLElement>);
        if(props.fontSize && primeraVez){
            setFontSize(Number(props.fontSize.split("px")[0]));
            botones.forEach(boton => {
                boton.style.fontSize = props.fontSize;
            })
            setPrimeraVez(false);
        }
        else{
            botones.forEach(boton => {
                boton.style.fontSize = fontSize.toString() + "px";
            })
        }
    }, [botonPausaHabilitado, botonSiguienteHabilitado, botonConfirmarHabilitado, botonOptionsHabilitado, botonRechazarHabilitado, botonConfirmarColor, fontSize, botonReenviarCodigoHabilitado])

    function botonConfirmarClick(){
        props.botonConfirmarClick();
    }

    function botonAnteriorClick(){
        props.botonAnteriorClick();
    }

    function botonSiguienteClick(){
        props.botonSiguienteClick();
    }

    function botonReenviarCodigoClick(){
        props.botonReenviarCodigoClick();
    }

    function botonModificarNumeroClick(){
        props.botonModificarNumeroClick();
    }

    function botonModificarCorreoClick(){
        props.botonModificarCorreoClick();
    }

    function botonReenviarCorreoClick(){
        props.botonReenviarCorreoClick();
    }

    function botonNoClick(){
        props.botonNoClick();
    }

    function botonSeleccionarClick(){
        props.botonSeleccionarClick();
    }

    async function expandirBarraOpciones(){
        var contenedor = document.getElementById("contenedor");
        if(!accionExpandida){
            await props.parentCallback("15%");
            setAccionExpandida(true);
            contenedor!.style.height = "15%";
        }
        else{
            await props.parentCallback("8%");
            setAccionExpandida(false);
            contenedor!.style.height = "8%";
        }
    }

    function cambiarTamanio(event:any){
        setFontSize(event.target.value);
        console.log(event.target.value.toString() + "px")
        global.fontSize = event.target.value.toString() + "px";
    }

    return (
        <> {!llamarAtenea && (        
            <div id="contenedor" className="contenedorBottom">
                {accionExpandida && (   
                    <div className="contenedorAccionesExpandido">             
                        <div className="contenedorAccionesExpandidoSuperior">
                            <p className="disminuirTexto">A-</p>
                            <input className="rangoTexto" type="range" min="10" max="13" value={fontSize} onChange={cambiarTamanio}></input>
                            <p className="aumentarTexto">A+</p>
                            <p className="descText">Cambia el tamaño de las letras de la barra de navegación</p>
                        </div>
                        <div className="contenedorAccionesExpandidoInferior">
                            <p className="descTexto descDismi">Disminuir letra</p>
                            <p className="descTexto descAumen">Aumentar letra</p>
                        </div>
                    </div>
                )}
                <div className="contenedorBotonAcciones">
                    <div className="botonAcciones">
                        {botonPausaHabilitado && (
                            <button className="boton">
                                <img src={PAUSE} alt="fotoPausa"/>
                                Pausar
                            </button>
                        )}
                        {botonAnteriorHabilitado && (
                            <button className="boton" onClick={botonAnteriorClick}>
                                <img src={ANTERIOR} alt="fotoAnterior"/>
                                Anterior
                            </button>
                        )}
                        {botonSiguienteHabilitado && (
                            <button className="boton" onClick={botonSiguienteClick}>
                                <img src={SIGUIENTE} alt="fotoSiguiente"/>
                                Siguiente
                            </button>
                        )}
                        {botonRechazarHabilitado && (
                            <button className="boton">
                                <img src={RECHAZAR} alt="fotoRechazar"/>
                                Rechazar
                            </button>
                        )}
                        {botonReenviarCodigoHabilitado && (
                            <button className="boton" onClick={botonReenviarCodigoClick}>
                                <img src={REENVIARCOD} alt="fotoReenviarCod"/>
                                Reenviar código
                            </button>
                        )}
                        {botonReenviarCorreoHabilitado && (
                            <button className="boton" onClick={botonReenviarCorreoClick}>
                                <img src={REENVIARCORR} alt="fotoReenviarCorr"/>
                                Reenviar Correo
                            </button>
                        )}
                        {botonModificarNumeroHabilitado && (
                            <button className="boton" onClick={botonModificarNumeroClick}>
                                <img src={MODIFICARNUM} alt="fotoModificarNum"/>
                                Modificar Número
                            </button>
                        )}
                        {botonModificarCorreoHabilitado && (
                            <button className="boton" onClick={botonModificarCorreoClick}>
                                <img src={MODIFICARNUM} alt="fotoModificarCorr"/>
                                Modificar Correo
                            </button>
                        )}
                        {botonNoHabilitado && (
                            <button className="boton" onClick={botonNoClick}>
                                <img src={NO} alt="fotoNo"/>
                                NO
                            </button>
                        )}
                        {botonConfirmarHabilitado && (
                            <button className={botonConfirmarColor === "blue" ? "confirmar" : "boton"} onClick={botonConfirmarClick}>
                                <img src={botonConfirmarColor === "blue" ? CONFIRMARBLUE : CONFIRMAR} alt="fotoConfirmar"/>
                                Confirmar
                            </button>
                        )}
                        {botonSeleccionarHabilitado && (
                            <button className="boton" onClick={botonSeleccionarClick}>
                                <img src={SELECCIONAR} alt="fotoSeleccionar"/>
                                Seleccionar
                            </button>
                        )}
                    </div>
                    {botonOptionsHabilitado && (
                        <div className="opciones">
                            <button onClick={expandirBarraOpciones} className="boton">
                                <img src={OPTIONS} alt="fotoOptions"/>
                            </button>
                        </div>
                    )}     
                </div>
            </div>
            )}
        </>
    )
}