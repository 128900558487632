import React, { useEffect, useRef, useState } from "react";
import TextFinal from "../textFinal";
import idImage from "./../../assets/cedula.png";
import "./TerminosAdvices.scss";
import { Link, useHistory } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import { Button2 } from "../utils/Buttons/Buttons";
import { addEvent } from "../api/Events/EventsApi";
import VectorCoreId from "../../assets/v10/VectorCoreID.svg";
import TopAppBar from "../AppBar/TopAppBar";
import BottomAppBar from "../AppBar/BottomAppBar";
import StorageManager from "../utils/StorageManager";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
function TerminosAdvices(props) {
  const [anteriorHabilitado, setAnteriorHabilitado] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef();
  const query = useQuery();
  const UrlCode = query.get("code");

  // Storage and skipping
  const history = useHistory();
  const storageManager = new StorageManager();
  const [bottomAppBarHeight, setBottomAppBarHeight] = useState("");
  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "slider",
    beforeChange: (oldIndex, newIndex) => {
      switch (newIndex) {
          default:
            setCurrentSlide(0)
            setAnteriorHabilitado(false);
            break;
          case 0: 
            setCurrentSlide(0)
            setAnteriorHabilitado(false);
            break;
          case 1:
            setCurrentSlide(1)
            setAnteriorHabilitado(true);
            break;
      }
    }
  };
  useEffect(() => {
    let project = storageManager.getProject();
    if (project.extraConfig) {
      if (project.extraConfig.showDocumentTips === false) {
        history.push(`/advices2?code=${UrlCode}`);
      }
    }
    if(bottomAppBarHeight !== "")
    {
      var tamanio = 100 - bottomAppBarHeight.split("%")[0];
      document.getElementById("contenedorTerminos").style.height = tamanio + "vh";
    }
  }, [bottomAppBarHeight]);
  function anterior(){
    sliderRef.current.slickGoTo(0);
    setAnteriorHabilitado(false);
  }

  function siguente(){
    if(currentSlide === 0){
      sliderRef.current.slickGoTo(1);
      setAnteriorHabilitado(true);
    }
    else{
      history.push(`/biometrics?code=${UrlCode}`);
    }
  }

  function handleCallback(bottomAppBar){
    setBottomAppBarHeight(bottomAppBar);
  }

  return (
    <div id="contenedorTerminos" className="contenedorTerminos">
      <TopAppBar/>
      <Slider ref={sliderRef} {...settings}>
        <div id="recomendacion1" className="recomendacion">
          <h2 className="tituloVerificacion">Verificación de Identidad</h2>
          <p className="indicacionDocSup">Ten a la mano</p>
          <p className="indicacionDocInf">tu documento de identidad</p>
          <p className="ubicacionDoc">Ubícate en un ambiente <br/> con buena LUZ</p>
          <p className="menajeQueremosVerteSup">pero no tanta...</p>
          <p className="menajeQueremosVerteInf">¡ Queremos verte a ti</p>
          <p className="menajeQueremosVerteInf">y tu documento muy bien !</p>
          <p className="permisoCamaraSup">No olvides</p>
          <p className="permisoCamaraInf">dar permisos a la cámara</p>
          <p className="permisoCamaraInf">de tu celular si te lo solicita</p>
        </div>
        <div id="recomendacion2" className="recomendacion">
          <p className="mensajeQueremos">Queremos obtener la mejor nitidez que tu cámara proporcione al tomar tu documento de identidad y tu rostro</p>
          <img src={VectorCoreId} alt="imagen core ID"/>
          <p className="mensajeConsejo">Un consejo<br/>Inclina ligeramente tu documento cuando realices la captura</p>
        </div>
      </Slider>
      {anteriorHabilitado && (
        <BottomAppBar parentCallback={handleCallback} fontSize={global.fontSize} botonAnteriorHabilitado={true} botonSiguienteHabilitado={true} botonOptionsHabilitado={true} botonSiguienteClick={siguente} botonAnteriorClick={anterior}/>
      )}
      {!anteriorHabilitado && (
        <BottomAppBar parentCallback={handleCallback} fontSize={global.fontSize} botonSiguienteHabilitado={true} botonOptionsHabilitado={true} botonSiguienteClick={siguente}/>
      )}
    </div>
  );
}

export default TerminosAdvices;
