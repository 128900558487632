import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import MoonLoader from "react-spinners/MoonLoader";

export interface SimpleLoadingObject {
  textTop?: string;
  textBottom?: string;
}

export interface LoadingOverlayCustomProps {
  loading: boolean;
  children: SimpleLoadingObject[];
  otherChildren?: React.ReactNode;
}

export const LoadingOverlayCustom = (props: LoadingOverlayCustomProps) => {
  const [index, setIndex] = useState(0);
  const [changeCount, setChangeCount] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % props.children.length);
      setChangeCount((prevChangeCount) => prevChangeCount + 1);
    }, 10000);

    return () => clearInterval(intervalId);
  }, [props.children.length]);

  if (props.otherChildren && changeCount % 0 === 0 && changeCount !== 0) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
          visibility: props.loading ? "visible" : "hidden",
          background: "rgba(0,0,0,0.5)",
        }}
      >
        {props.otherChildren}
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
          visibility: props.loading ? "visible" : "hidden",
          background: "rgba(0,0,0,0.5)",
        }}
      >
        <h5
          style={{
            fontSize: "24px",
            fontWeight: 400,
            width: "40%",
            marginTop: "-40px",
            textAlign: "center",
            marginBottom: "24px",
            color: "#ffffff",
          }}
        >
          {props.children[index].textTop || "               "}
        </h5>
        <ClipLoader color={"#fff"} loading={props.loading} size={90} />
        <h5
          style={{
            fontSize: "24px",
            fontWeight: 400,
            width: "40%",
            textAlign: "center",
            marginTop: "24px",
            color: "#ffffff",
          }}
        >
          {props.children[index].textBottom || "               "}
        </h5>
      </div>
    );
  }
};
