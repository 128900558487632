import React, { useEffect, useState } from "react";
import icono2 from "./../../assets/scan.png";
import "./IntroCheck.scss";
import icon3 from "./../../assets/LOCATION.png";
import icon4 from "./../../assets/Group.png";
import Checkbox from "@material-ui/core/Checkbox";
import { Row, Col } from "react-bootstrap";
import { Button1, Button2 } from "./../utils/Buttons/Buttons";
import { useHistory, Link } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import { infoMessage } from "../utils/ErrorHandler/errorHandler";
import iconoanteia from "../../assets/v10/anteiaR.svg";
import iconocontacto from "../../assets/v10/VectorID.svg";
import iconocamara from "../../assets/v10/VectorAperture.svg";
import iconomicrofono from "../../assets/v10/VectorMicrophone.svg";
import iconosms from "../../assets/v10/VectorSMS.svg";
import iconohuella from "../../assets/v10/VectorFingerprint.svg";
import iconoubicacion from "../../assets/v10/VectorLocation.svg";
import BottomAppBar from "../AppBar/BottomAppBar";
function Introcheck(props) {
  let history = useHistory();
  const query = useQuery();
  const UrlCode = query.get("code");
  const [acept, setAcept] = useState(false);
  const [bottomAppBarHeight, setBottomAppBarHeight] = useState("");
  const onChange = (e) => {
    setAcept(!acept);
  };

  useEffect(()=>{
    if(bottomAppBarHeight !== "")
    {
        var tamanio = 100 - bottomAppBarHeight.split("%")[0];
        document.getElementById("introcheck").style.height = tamanio + "vh";
    }
  }, [bottomAppBarHeight])
  
  function showAlert() {
    if (acept) {
      history.push(`/intro/?code=${UrlCode}`);
    } else {
      infoMessage("DEBES ACEPTAR LOS TERMINOS Y CONDICIONES PARA CONTINUAR");
    }
  }

  function handleCallback(bottomAppBar){
    setBottomAppBarHeight(bottomAppBar);
  }

  return (
    <div id="introcheck" className="introcheck">
      <p className="txt-line-one">
        Antes de comenzar
      </p>
      <img className="imgLogo" src={iconoanteia} loading="eager" alt="logo anteia"/>
      <p className="mensajeAcceso">
        necesitará acceder, recolectar <br/>
        y almacenar datos de tu equipo
      </p>
      <div className="contenedorIconos">
        <img className="imgIcono" src={iconocontacto} alt="icono contacto"/>
        <img className="imgIcono" src={iconocamara} alt="icono camara"/>
        <img className="imgIcono" src={iconomicrofono} alt="icono microfono"/>
        <img className="imgIcono" src={iconosms} alt="icono sms"/>
        <img className="imgIcono" src={iconohuella} alt="icono huella"/>
        <img className="imgIcono" src={iconoubicacion} alt="icono ubicacion"/>
      </div>
      <div className="contenedorAceptoTerminos">
        <input type="checkbox"
          size="medium"
          className="checkbox_accepterms"
          checked={acept}
          onChange={onChange}
          inputProps={{
            "aria-label": "checkbox with default color",
          }}
        />
        <p className="txt-terminos">
          Marca esta opción para dar el consentimiento libre, expreso que has sido informado de los términos y condiciones, política de privacidad y tratamiento de datos sensibles.
        </p>
      </div>
      <Link className="linkLeerMas" to={`/readmore/?code=${UrlCode}`}>
        <p className="txtLeerMas">(leer más)</p>
      </Link>
      <BottomAppBar parentCallback={handleCallback} botonConfirmarHabilitado={true} botonRechazarHabilitado={true} botonOptionsHabilitado={true} botonConfirmarClick={showAlert}/>
    </div>
  );
}
export default Introcheck;
