import React, { useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Link, useHistory } from "react-router-dom";
import "./ConfirmEmailScreen.scss";
import Loader from "../utils/Loader/Loader";
import useQuery from "../../hooks/useQuery";
import { successMessage } from "../utils/ErrorHandler/errorHandler";
import { addEvent } from "../api/Events/EventsApi";
const secondsToWait = 120;

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function getTime() {
  var d = new Date();
  return d.getTime();
}

function ConfirmEmailInPlaceScreen(props) {
  const query = useQuery();
  const UrlCode = query.get("code");

  let history = useHistory();
  function gotoClose() {
    history.push("/closeWindow");
  }
  useEffect(() => {
    addEvent("EMAIL_CONFIRMED", {}, {}, "interaction");
    async function getDataDone() {
      var confEmail = await props.api.confirmEmailNew(UrlCode);
      await successMessage("Correo confirmado!");
    }
    getDataDone();
  }, []);
  return (
    <div className="row confirmEmail" style={{ color: "white" }}>
      <div
        className="col-12 verify"
        // style={{ padding: "1%", paddingBottom: "0%", marginTop: "10%", textAlign: "center" }}
      >
        <h2>Listo! Tu correo está verificado</h2>
        {/* <Loader /> */}
        {/* <LoadingOverlay
          active={true}
          // spinner={<BounceLoader />}
          spinner={true}
        />  */}
        <h2>Puedes cerrar esta ventana</h2>
      </div>
    </div>
  );
}
export default ConfirmEmailInPlaceScreen;
