import React from "react";
import iconoanteia from "../../assets/v10/introAPPgreen.mp4";
import "./IntroLogo.scss";
import { detect } from "detect-browser";
import { infoMessage } from "../utils/ErrorHandler/errorHandler";
const browser = detect();
function IntroLogo(props) {
  function getLogo() {
    if (props.logoUrl && props.logoUrl !== "") {
      return props.logoUrl;
    } else {
      return iconoanteia;
    }
  }
  // if (browser.os === "iOS") {
  //   if (browser.name != "safari") {
  //     infoMessage("TU NAVEGADOR NO ES COMPATIBLE TE SUGERIMOS USAR SAFARI");
  //   }
  // } else if (browser.os === "android") {
  //   if (
  //     browser.name != "chrome" ||
  //     browser.name != "samsung" ||
  //     browser.name != "firefox"
  //   ) {
  //     infoMessage("TU NAVEGADOR NO ES COMPATIBLE TE SUGERIMOS USAR CHROME");
  //   }
  // } else {
  //   console.log(browser.os);
  // }

  // if (browser) {
  //   console.log(browser.name);
  //   console.log(browser.version);
  //   console.log(browser.os);
  // }
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <video
        style={{ backgroundSize: "cover", width: "100wh" }}
        autoPlay
        playsInline
        muted
        src={iconoanteia}
        type="video/webm"
      />
    </div>
  );
}
export default IntroLogo;
