import React, { useEffect, useState } from "react";
import "./HuellaBiometrica.scss";
import { Row, Col } from "react-bootstrap";
import icon from "./../../assets/GAFAS.png";
import icon2 from "./../../assets/VARITA.png";
import icon3 from "./../../assets/LUZ.png";
import icon4 from "./../../assets/SOMBRERO.png";
import icon5 from "./../../assets/tapaboca.png";
import img from "./../../assets/verification.png";
import { Button2 } from "../utils/Buttons/Buttons";
import useQuery from "../../hooks/useQuery";
import { Link, useHistory } from "react-router-dom";
import StorageManager from "../utils/StorageManager";
import anteiaTik from "../../assets/v10/anteiaTik.mp4";
import BottomAppBar from "../AppBar/BottomAppBar";
function HuellaBiometrica(props) {
  const query = useQuery();
  const UrlCode = query.get("code");
  const [bottomAppBarHeight, setBottomAppBarHeight] = useState("");
  // Storage and skipping
  const history = useHistory();
  const storageManager = new StorageManager();
  useEffect(() => {
    let project = storageManager.getProject();
    if (project.extraConfig) {
      if (project.extraConfig.showFaceTips === false) {
        history.push(`/videoface?code=${UrlCode}`);
      }
    }
    if(bottomAppBarHeight !== "")
    {
      var tamanio = 100 - bottomAppBarHeight.split("%")[0];
      document.getElementById("contenedorHuellaBio").style.height = tamanio + "vh";
    }
  }, [bottomAppBarHeight]);

  function siguente(){
    history.push(`/videoface?code=${UrlCode}`)
  }

  function handleCallback(bottomAppBar){
    setBottomAppBarHeight(bottomAppBar);
  }
  
  return (
    <div id="contenedorHuellaBio" style={{height: "92vh", width: "100vw", display: "flex", flexDirection: "column"}}>
      <video style={{width: "100%", opacity: "40%", height: "100%", backgroundSize: "cover"}} autoPlay playsInline muted src={anteiaTik} type="video/mp4" loop/>
      <BottomAppBar parentCallback={handleCallback} botonSiguienteHabilitado={true} botonOptionsHabilitado={true} botonSiguienteClick={siguente}/>
    </div>
  );
}
export default HuellaBiometrica;
