import React, { useState, useEffect } from "react";
import { Container, Col } from "react-bootstrap";
import "./RecolectDirection.scss";
import { useHistory } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import { addEvent } from "../api/Events/EventsApi";
import { Button2 } from "./../utils/Buttons/Buttons";
import StorageManager from "../utils/StorageManager";
import CityMapImage from "../../assets/v10/VectorCityMap.svg";
import TopAppBar from "../AppBar/TopAppBar";
import BottomAppBar from "../AppBar/BottomAppBar";
import Geocode from "react-geocode";

import LoadingOverlay from "react-loading-overlay";
function createMapOptions(maps) {
  return {
    styles: [
      { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
      { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
      { elementType: "labels.text.fill", stylers: [{ color: "#ffffff" }] },
      {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#263c3f" }],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#38414e" }],
      },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ color: "#212a37" }],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9ca5b3" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#1f2835" }],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#f3d19c" }],
      },
      {
        featureType: "transit",
        elementType: "geometry",
        stylers: [{ color: "#2f3948" }],
      },
      {
        featureType: "transit.station",
        elementType: "labels.text.fill",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#17263c" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#515c6d" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#17263c" }],
      },
    ],
  };
}

/* const defaultProps = {
  center: {
    lat: 4.363496,
    lng: -74.4543,
  },
  zoom: 11,
}; */
function RecolectDirection(props) {
  let history = useHistory();
  let stateGps = false;
  const query = useQuery();
  const UrlCode = query.get("code");
  const [llamarAtenea, setLlamarAtenea] = useState(false);
  const [bottomAppBarHeight, setBottomAppBarHeight] = useState("");


  // Storage and skipping
  const storageManager = new StorageManager();
  useEffect(() => {
    let project = storageManager.getProject();
    console.log(project);
    if (project) {
      if (project.hasLocalization === false) {
        if (!props.needsPassword) {
          history.push(`/vemail?code=${UrlCode}`);
        } else {
          history.push(`/password?code=${UrlCode}`);
        }
      }
    }
    if(bottomAppBarHeight !== "")
    {
      var tamanio = 100 - bottomAppBarHeight.split("%")[0];
      document.getElementById("contenedorRecolectDirection").style.height = tamanio + "vh";
    }
  }, [bottomAppBarHeight]);

  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [api, setApi] = useState(props.api);
  const [direction, setDirection] = useState({
    direccion: "",
    ciudad: "",
    pais: "",
    latitud: 4.363496,
    longitud: -74.4543,
  });

  const updateUbication = (e) => {
    setLoading(false);
    setDirection({
      ...e,
    });
  };
  const ConfirmDirection = () => {
    addEvent("GEOREF_SCREEN_ACCEPT", {}, {}, "interaction");
    if (!props.needsPassword) {
      history.push(`/vemail?code=${UrlCode}`);
    } else {
      history.push(`/password?code=${UrlCode}`);
    }
  };

  function error(){
    setLlamarAtenea(true);
    setLoading(false);
  }

  function sucess(position){
    Geocode.setApiKey("AIzaSyDdT6vOLnI4C_rEgapqWGakLqUFyMEFRik");
    Geocode.setLanguage("en");
    // Geocode.setLocationType("ROOFTOP");
    Geocode.enableDebug();
    Geocode.fromLatLng(position.coords.latitude, position.coords.longitude)
      .then((response) => {
        addEvent(
          "GEOREF_ACCEPT",
          { lon: position.coords.longitude, lat: position.coords.latitude },
          {},
          "interaction"
        );
        const address = response.results[0].formatted_address;
        let latitude,
          longitude,
          locality,
          administrativearealevel2,
          state,
          country,
          postalcode;
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                locality = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_2":
                administrativearealevel2 =
                  response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
              case "postal_code":
                postalcode =
                  response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        if (stateGps === false) {
          let datos = api.GeolocationPageDireccion(
            latitude,
            longitude,
            address,
            locality,
            administrativearealevel2,
            state,
            country,
            postalcode
          );
          setDirection({
            direccion: datos.address,
            ciudad: datos.locality,
            pais: datos.country,
            latitud: datos.latitudeUser,
            longitud: datos.longitudeUser,
          });
          setLoading(false);
          stateGps = true;
          if(llamarAtenea){
            setLlamarAtenea(false)
          }
        } else {
          console.log("call gps method off");
        }
      })
  }

  async function getLocation() {
    try {
      navigator.geolocation.getCurrentPosition(sucess, error);
      // let resp = await api.confirmotp(updateUbication);
      // if (resp && resp.address) {
      //   setLoading(false);
      //   console.log(resp);
      // } else {
      //   if (!props.needsPassword) {
      //     history.push(`/vemail?code=${UrlCode}`);
      //   } else {
      //     history.push(`/password?code=${UrlCode}`);
      //   }
      // }
    } catch (e) {
      console.log(e);
      if (!props.needsPassword) {
        history.push(`/vemail?code=${UrlCode}`);
      } else {
        history.push(`/password?code=${UrlCode}`);
      }
    }
  }

  function handleCallback(bottomAppBar){
    setBottomAppBarHeight(bottomAppBar);
  }

  useEffect(() => {
    addEvent("STEP_GEOREF", {}, {}, "interaction");
    getLocation();
  }, []);

  const defaultProps = {
    center: {
      lat: direction.latitud,
      lng: direction.longitud,
    },
    zoom: 11,
  };
  if (loading) {
    return (
      <div>
        <LoadingOverlay
          active={true}
          spinner
          text="Espera un momento"
          classNamePrefix="MyLoader_spinner"
        />
      </div>
    );
  }

  return (
    <div id="contenedorRecolectDirection" className="App" style={{ color: "white", backgroundImage: `url(${CityMapImage})`, backgroundSize: "cover", height: "100vh" }}>
      {llamarAtenea && (
        <TopAppBar llamarAtenea={llamarAtenea} getLocation={getLocation}/>
      )}
      {!llamarAtenea &&(
        <TopAppBar/>
      )}
      {!llamarAtenea && (
      <Container className="container_recolectDirection">
        <Col>
          <p className="text_1">
            VERIFIQUEMOS <br />
            TU UBICACIÓN <br />
          </p>
          <div>
            <h6>DIRECCIÓN</h6>
            <input
              value={direction.direccion}
              name="direccion"
              onChange={updateUbication}
            />
          </div>

          <div>
            <h6>CIUDAD</h6>
            <input
              value={direction.ciudad}
              name="ciudad"
              onChange={updateUbication}
            />
          </div>
          <div>
            <h6>PAÍS</h6>
            <input
              value={direction.pais}
              name="pais"
              onChange={updateUbication}
            />
          </div>
          {/* <button
            type="button"
            className="btn_continue"
            onClick={ConfirmDirection}
          >
            <h1>CONTINUAR</h1>
          </button> */}
        </Col>
      </Container>
      )}
      {llamarAtenea && (
        <BottomAppBar parentCallback={handleCallback} llamarAtenea={llamarAtenea} fontSize={global.fontSize} botonSiguienteHabilitado={true} botonOptionsHabilitado={true} botonSiguienteClick={ConfirmDirection}/>
      )}
      {!llamarAtenea &&(
        <BottomAppBar parentCallback={handleCallback} fontSize={global.fontSize} botonSiguienteHabilitado={true} botonOptionsHabilitado={true} botonSiguienteClick={ConfirmDirection}/>
      )}
    </div>
  );
}

export default RecolectDirection;
