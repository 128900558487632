import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateTextField.scss";

export const DateTextField = ({
  label,
  selectedDate,
  setSelectedDate,
  maxDate,
  disabled,
}) => {
  const labelStyle = {
    position: "absolute",
    top: "-0.55rem",
    left: "1.5rem",
    pointerEvents: "none",
    transition: "all 0.2s ease-out",
    textTransform: "uppercase",
    fontSize: "0.75rem",
    color: "rgba(255,255,255,0.75)",
  };
  return (
    <div style={{ position: "relative", marginBottom: "1rem" }}>
      <DatePicker
        selected={selectedDate}
        onChange={(date) => {
          setSelectedDate(date);
        }}
        dateFormat="dd/MM/yyyy"
        maxDate={maxDate}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        className="custom-datepicker"
        disabled={disabled}
      />
      <label style={labelStyle}>{label}</label>
    </div>
  );
};
