import React, { useEffect, useState } from "react";
import WebcamHTML from "../utils/webcam";
import { useRef } from "react";
import {
  fatalError,
  commonError,
  infoMessage,
  retryMessage,
} from "../utils/ErrorHandler/errorHandler";
// import { isMobile } from "../utils/utils";
import useQuery from "../../hooks/useQuery";
import Models from "../api/api-models";
import { useHistory } from "react-router-dom";

import "./RecolectDataVideo.scss";

import { addEvent } from "../api/Events/EventsApi";
import LoadingOverlay from "react-loading-overlay";

import arrow1 from "./../../assets/Polygon2.png";
import arrow2 from "./../../assets/Polygon.png";
import iconcheck from "./../../assets/CHECK2.png";
import {
  CommandTextsByColor,
  CommandTextsByColorDown,
  CommandTextsByColorTop,
} from "./../utils/TextsVideo/TextsCedula";
import { CommandTextsByFace } from "./../utils/TextsVideo/TextsFace";
import { CommandTextsTarjetaPropiedad } from "./../utils/TextsVideo/TextsPropertyCard";
import { CommandTextsLicencia } from "./../utils/TextsVideo/TextsLicencia";
import Loader from "../utils/Loader/Loader";
import {
  hintMessageBorroso,
  hintMessageReflejo,
  hintMessageRecorteAgente,
} from "../utils/ErrorHandler/hintMessages";
import StorageManager from "../utils/StorageManager";

import {
  hintMessageBorrosoFace,
  hintMessageReflejoFace,
} from "../utils/ErrorHandler/hintMessagesface";
import { LoadingOverlayCustom } from "../utils/Loader/LoadingOverlayCustom";
import iconoanteia from "../../assets/v10/anteia-particle-1080x1920-edit.webm";

function RecolectDataVideo(props) {
  let history = useHistory();
  const query = useQuery();
  const regId = props.registrationId;
  const token = props.token;
  const queryCode = query.get("code");
  const tf = props.tf;

  const bg1 = props.bg1;
  const bg2 = props.bg2;

  const webcamRef = useRef(null);
  const [models, setModels] = useState();
  const [wcElement, setWcElement] = useState();
  const [videoRostro, setVideoRostro] = useState(false);
  const [styleFace, setStyleFace] = useState(false);
  const api = props.api;
  //Async biometrics
  // 5 cedula front, 6 cedula back, 2 cara1, 3 cara2, 4 voz
  const [bioReqs, setBioReqs] = useState([6, 5]);
  //const [bioReqs, setBioReqs] = useState([5]);
  //FAILED STUFF
  const [getFailsSteps, setFailSteps] = useState([]);
  const [getFailMsg, setFailMsg] = useState([]);
  const [getFailImg, setFailImg] = useState([]);
  const [getFailImgAgent, setFailImgAgent] = useState([]);
  const [getFailCode, setFailCode] = useState([]);

  const [step, setStep] = useState(0);
  const [needsFlujo, setNeedsFlujo] = useState(false);
  const [reqsQueue, setReqsQueue] = useState([]);
  const [capture, setCapture] = useState(false);
  const [record, setRecord] = useState(false);
  const [colorCuadrito, setColorCuadrito] = useState("#ffffff6f");
  const [showCuadradito, setShowCuadradito] = useState(true);
  const [modelsLoaded, setModelsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setisActive] = useState(true);
  // -------------------texto_error-------------------------------

  const [txtErrorCedula, setTextErrorCedula] = useState("");

  // storage
  const [storageManager, _] = useState(new StorageManager());

  useEffect(() => {
    if (props.needsPase) {
      bioReqs.unshift(7);
      bioReqs.unshift(8);
    }
    if (props.needsTarjetaPropiedad) {
      bioReqs.unshift(9);
      bioReqs.unshift(10);
    }
    if (window.location.href.includes("retryFront")) {
      bioReqs.splice(bioReqs.indexOf(6), 1);
    }
    if (window.location.href.includes("confirmData")) {
      bioReqs.splice(bioReqs.indexOf(6), 1);
      bioReqs.unshift(6);
      console.log("reqsQueue", reqsQueue);
    }
    if (storageManager.getDocType() == "PASAPORTE") {
      console.log("PASAPORTE, SKIPPING 6");
      bioReqs.splice(bioReqs.indexOf(6), 1);
    }
  }, []);

  const nextAtomatic2 = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  //Advances the step
  const nextAtomatic = (ms) => {
    // console.log("nextAutomatic: " + ms);
    nextAtomatic2(ms).then(() => document.getElementById("next").click());
  };

  function FlujoAlterno(typePass) {
    api.log("Step: " + step, "FlujoAlterno()");
    setCapture(true);
    infoMessage("Toca la pantalla para capturar la imagen");
    models.StartCameraCaptureMobile(typePass);
  }

  const nextBioStep = () => {
    // console.log("NEXT BIO STEP");
    let localStep = reqsQueue.pop();
    // console.log(localStep);
    // console.log(models);
    updateStep(localStep + 1);
  };

  function addHintMsg(msg) {
    console.log("Adding Hint message!");
    console.log(msg);
    getFailMsg.push(msg);
  }

  function addFailImg(img) {
    console.log("Adding failed Img!");
    getFailImg.push(img);
  }

  function addFailCode(code) {
    console.log("Adding failed Code: " + code);
    getFailCode.push(code);
  }

  function addFailImgAgent(img) {
    getFailImgAgent.push(img);
  }

  const acceptStep = (step2) => {
    console.log("reqsQueue");
    console.log(reqsQueue);
    console.log("bioReqs");
    console.log(bioReqs);
    console.log("Accept Step: " + step2);
    if (bioReqs.indexOf(step2) != -1) bioReqs.splice(bioReqs.indexOf(step2), 1);
    console.log("reqsQueue");
    console.log(reqsQueue);
    console.log("bioReqs");
    console.log(bioReqs);
  };

  function updateStep2() {
    if (step <= 1 && step != -1) {
      // console.log("BIO REQS ON START");
      // console.log(bioReqs);
      // console.log("QUEUE ON START");
      // console.log(reqsQueue);
    }
    if (step <= 11) {
      // console.log("NEXT BIO STEP");
      let localStep = reqsQueue.pop();
      // console.log(localStep);

      if (localStep === 4) {
        // acceptStep(4);
        // nextAtomatic(7000);
      }
      if (bioReqs.length === 0) {
        nextAtomatic(200);
        updateStep(12);
        return;
      } else if (!localStep) {
        setIsLoading(true);
        wcElement.stopRecording();
        // console.log("");
        // console.log("Reqs");
        // console.log(bioReqs);
        // console.log("step");
        // console.log(step);
        nextAtomatic(400);
        updateStep(-1);
        setShowCuadradito(true);
        return;
      }
      updateStep(localStep);
      setisActive(false);
      setIsLoading(false);
      //TEMP
    } else {
      setisActive(false);
      setIsLoading(false);
      updateStep(step);

      if (record === true) {
        // handleStopCaptureClick();
        // stopRecording();
      }

      console.log("updatePage: recolect");
      //Aca poner la siguiente RUTA!!!!
      wcElement.stopCameras().then(() => {
        // api.checkLists();
        models.disconnectAgent();
        if (window.location.href.includes("retryFront")) {
          history.push(`/vsms?code=${queryCode}`);
          console.log("EXIT FROM BIOMETRICS");
          api.executeCv();
          return;
        }
        if (window.location.href.includes("confirmData")) {
          history.push(`/final?code=${queryCode}`);
          console.log("EXIT FROM BIOMETRICS");
          api.executeCv();
          return;
        }
        history.push(`/huella?code=${queryCode}`);
        console.log("EXIT FROM BIOMETRICS");
      });

      // history.push(`/biometrics?code=${UrlCode}`);
    }
  }

  const repeatStep = (step2) => {
    console.log("reqsQueue");
    console.log(reqsQueue);
    console.log("bioReqs");
    console.log(bioReqs);
    console.log("Repeat Step: " + step2);
    reqsQueue.unshift(step2);
    console.log("reqsQueue");
    console.log(reqsQueue);
    console.log("bioReqs");
    console.log(bioReqs);
    getFailsSteps.push(step2);
  };

  const updateStep = async (stepR) => {
    // console.log("updateStep");
    // console.log(stepR);
    setStep(stepR);
    if (getFailsSteps.includes(stepR, 0)) {
      let msg = "";
      msg = getFailMsg.pop();
      let code = getFailCode.pop();
      let img = getFailImg.pop();
      console.log("CODE IN SHOW MESSAGE");
      console.log(code);
      if (code === "103") {
        var res = await hintMessageBorroso(img, bg1, bg2);
      } else if (code === "102") {
        var res = await hintMessageReflejo(img, bg1, bg2);
      } else if (code === "112") {
        img = getFailImgAgent.pop();
        console.log(img);
        var res = await hintMessageRecorteAgente(img, bg1, bg2, msg);
      } else {
        var res = await retryMessage(msg, "Volver a capturar");
      }
    }

    try {
      switch (stepR) {
        case 0:
          console.log("paso:INICIO");
          break;
        case 1:
          models.predictFilters("filtros");
          break;

        case 2:
          addEvent("STEP_FACE", {}, {}, "interaction");
          setVideoRostro(true);
          setStyleFace(true);
          needsFlujo ? FlujoAlterno(2) : models.predictface("cara", 2);
          console.log("paso:ROSTRO");
          // drawCuadraditoCedula();
          break;
        case 3:
          setVideoRostro(true);
          setStyleFace(true);
          needsFlujo ? FlujoAlterno(3) : models.predictface("cara", 3);
          console.log("paso:IRIS");
          // drawCuadraditoCedula();
          break;
        case 5:
          setVideoRostro(false);
          drawCuadraditoCedula();
          needsFlujo ? FlujoAlterno(5) : models.predictid("cedula-front", 5);
          addEvent("STEP_DOCUMENT", { document: "Cedula" }, {}, "interaction");
          break;
        case 6:
          setVideoRostro(false);
          drawCuadraditoCedula();
          console.log("paso:IDSECOND");
          needsFlujo ? FlujoAlterno(6) : models.predictid("cedula-back", 6);

          break;
        // Licencia conduccion
        case 7:
          setVideoRostro(false);
          console.log("LICECIA 1");
          needsFlujo ? FlujoAlterno(5) : models.predictMock("cedula-front", 7);
          addEvent(
            "STEP_DOCUMENT",
            { document: "Licencia" },
            {},
            "interaction"
          );
          break;
        case 8:
          setVideoRostro(false);
          console.log("LICECIA 2");
          needsFlujo ? FlujoAlterno(6) : models.predictMock("cedula-back", 8);

          break;
        // Licencia conduccion
        case 9:
          setVideoRostro(false);
          drawCuadraditoCedula();
          console.log("TARJETA 1");
          needsFlujo ? FlujoAlterno(5) : models.predictMock("cedula-front", 9);
          addEvent("STEP_DOCUMENT", { document: "Tarjeta" }, {}, "interaction");
          break;
        case 10:
          setVideoRostro(false);
          drawCuadraditoCedula();
          console.log("TARJETA 2");
          needsFlujo ? FlujoAlterno(6) : models.predictMock("cedula-back", 10);

          break;
        default:
          break;
      }
    } catch (e) {
      alert("Error:" + e.toString());
    }
  };

  function textsWait() {
    console.log("texts start working wait ...");
    let txts = [["Espera un momento, estamos validando tu documento"]];
    return (
      <>
        <div className="color1">
          <h2 className="AVGARDNI txt">{txts[0][0]}</h2>
          <h2 className="AVGARDNI txt">{txts[0][1]}</h2>
        </div>
      </>
    );
  }

  useEffect(() => {
    console.log("UseEffect RecolectBio");
    loadModels();
  }, []);

  useEffect(() => {
    if (modelsLoaded) updateStep2();
  }, [modelsLoaded]);

  async function loadModels() {
    // await props.modelsLoader.loadModels()
    initCamera();
  }
  async function initCamera() {
    if (reqsQueue.length === 0)
      bioReqs.forEach((val) => {
        reqsQueue.push(val);
      });
    console.log("Init Camera");
    // tf.enableProdMode();
    // await tf.ready();
    let element = new WebcamHTML(
      document.getElementById("webcam"),
      tf,
      api,
      sendToPhone
    );
    setWcElement(element);
    // element.setupBack();
    console.log("REGISTRATION ID IN TF>READY: " + regId);
    console.log("TOKEN IN TF>READY: " + token);
    var res = true;
    // console.log(props.modelsLoader)
    var modelId = props.modelsLoader.modelId;
    var modelFace = props.modelsLoader.modelFace;
    if (!modelId || !modelFace) {
      await props.modelsLoader.loadModels(tf);
      modelId = props.modelsLoader.modelId;
      modelFace = props.modelsLoader.modelFace;
    }
    if (!models) {
      let models2 = new Models(
        token,
        tf,
        element,
        regId,
        // setLoading,
        drawCuadraditoCedula,
        quitarCuadraditoCecdula,
        repeatStep,
        acceptStep,
        nextBioStep,
        sendToPhone,
        setColorCuadrito,
        api,
        () => {}, //ShowLoader
        modelId,
        modelFace,
        props.country,
        addHintMsg,
        addFailImg,
        addFailCode,
        addFailImgAgent
      );
      // res = await models2.loadModel();

      setModels(models2);
      element.models = models2;
      setModelsLoaded(true);
    }
  }

  function drawCuadraditoCedula() {
    setShowCuadradito(true);
  }

  function quitarCuadraditoCecdula() {
    setShowCuadradito(false);
  }

  function sendToPhone() {}
  console.log(step);
  return (
    <div className="video-style">
      {(step === 5 || step === 6) && (
        <div className="child-3">
          <div className="cont-picture">
            {showCuadradito && (
              <div
                className={
                  colorCuadrito === "#ffffff6f"
                    ? "style-before-cedula"
                    : "style-scan-cedula"
                }
              >
                {/* -----------------------ICON ARROW LEFT CEDULA----------------------------- */}
                {colorCuadrito !== "#ffffff6f" && (
                  <>
                    <div className="div-left-cedula">
                      <div className="img-content">
                        <img src={arrow1} alt="icon" />
                      </div>
                    </div>
                  </>
                )}
                {/* ----------------------------------------------------------------------------------------- */}

                <div style={{ display: "flex" }}>
                  {/* -----------------------TEXTS DOWN CEDULA ----------------------------- */}
                  <div style={{ width: "3em" }}>
                    {(step === 5 || step === 6) &&
                      CommandTextsByColorDown({
                        step,
                        colorCuadrito,
                      })}
                  </div>
                  {/* ----------------------------------------------------------------------------------------- */}
                  <div
                    className={
                      colorCuadrito === "#ffffff6f"
                        ? "inner-picture-color"
                        : "inner-picture"
                    }
                    style={
                      step === 5 || step === 6
                        ? { border: "3px solid" + colorCuadrito }
                        : { display: "none" }
                    }
                  >
                    {/* -----------------------TEXT IN SQUARE "PRIMERO FRENTE"----------------------------- */}

                    {(step === 5 || step === 6) &&
                      CommandTextsByColor({ step, colorCuadrito })}
                    {(step === 7 || step === 8) &&
                      CommandTextsLicencia({ step, colorCuadrito })}
                    {(step === 9 || step === 10) &&
                      CommandTextsTarjetaPropiedad({ step, colorCuadrito })}
                    {/* {step === -1 && textsWait()} */}
                    {/*---------------------------------------------------------------------------------------*/}
                  </div>

                  {/* -----------------------TEXTS TOP CEDULA ----------------------------- */}
                  <div style={{ width: "3em" }}>
                    {(step === 5 || step === 6) &&
                      CommandTextsByColorTop({
                        step,
                        colorCuadrito,
                      })}
                  </div>
                </div>
                {/* ------------------------------------------------------------------------- */}

                {/* -----------------------ICON ARROW RIGHT CEDULA----------------------------- */}
                {colorCuadrito !== "#ffffff6f" && (
                  <>
                    <div className="div-right-cedula">
                      {colorCuadrito == "#00ff44" ? (
                        <>
                          <div className="img-content">
                            <img src={iconcheck} alt="icon" />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="img-content">
                            <img src={arrow2} alt="icon" />
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
                {/* ----------------------------------------------------------------------------------------- */}
              </div>
            )}
          </div>
        </div>
      )}

      <div className={"video-stream"}>
        <video
          autoPlay
          playsInline
          id="webcam"
          style={
            step == 5 || step == 6
              ? { border: "0px solid" + colorCuadrito }
              : { display: "none" }
          }
          className={videoRostro === true ? "videoFace" : "nada2"}
          // playsInline="true"
          audio="false"
          ref={webcamRef}
        />
      </div>
      {/* <div style={loaderText.length !== 0 ? {} : { display: "none" }}>  */}

      {/* <LoadingOverlay
        active={loaderText.length !== 0}
        spinner
        text={loaderText}
        classNamePrefix="MyLoader_spinner"
      /> */}

      <LoadingOverlayCustom
        loading={isLoading}
        children={[
          {
            textBottom: "Un momento por favor",
            textTop: "Estamos procesando tu información",
          },
          {
            textBottom: "Tu documento está siendo verificado",
            textTop: "Un instante por favor",
          },
          {
            textBottom: "Estamos validando tu documento",
          },
        ]}
        otherChildren={
          <>
            <h6
              style={{
                width: "200px",
                height: "25px",
                fontFamily: "Open Sans",
                fontStyle: "normal",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "600",
                position: "absolute",
                top: "35%",
                zIndex: "100",
                color: "#ffffff",
              }}
            >
              Sistema de Onboarding digital de
            </h6>
            <video
              style={{
                opacity: "40%",
                backgroundSize: "cover",
                marginTop: "-50px",
              }}
              autoPlay
              src={iconoanteia}
              type="video/webm"
            />
          </>
        }
      />

      <button hidden={true} id="next" onClick={() => updateStep2()}></button>
    </div>
  );
}
export default RecolectDataVideo;
