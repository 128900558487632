import axios from "axios";
import * as rax from "retry-axios";
import { commonError, infoMessage } from "../utils/ErrorHandler/errorHandler";
import { isMobile } from "../utils/utils";
import { getDataserverUrl, isProd } from "../utils/envSelector";
import { Link, useHistory } from "react-router-dom";
import StorageManager from "../utils/StorageManager";
import { setInfo, addEvent } from "./Events/EventsApi";

// const baseurldataserver = "http://localhost:8080";
const baseurldataserver = getDataserverUrl();
const baseurlcvserver = "https://dev.cv-server.anteia.co";
// const baseurlcvserver = "https://services.anteia.co";

rax.attach();
export default class Api {
  constructor(setDeviceId) {
    this.token = "";
    this.setDeviceId = setDeviceId;
    this.history = useHistory();
    this.storage = new StorageManager();
  }

  getToken() {
    console.log("getting token");
    if (this.token && this.token !== "") {
      console.log("got token from memory");
      return this.token;
    } else {
      let token2 = this.storage.getToken();
      if (token2 !== "") {
        console.log("got token from storage");
        this.token = token2;
        return this.token;
      }
    }
  }

  // ------------------------------------------------
  // --------------QR FLOW STUFF---------------------
  // ------------------------------------------------

  //--------------------forceDataDone
  async forceDataDone() {
    console.log("Forcing DataDone...");
    var url = baseurldataserver + "/qrFlow/forceDatadone";
    let r = await axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        Authorization: "Bearer " + this.getToken(),
      },
    });
    console.log(r);
  }

  // --------------SEND LINK SMS-----------------------
  async sendLink(phone) {
    console.log("sendLINK!!!!!");
    var code = this.getQueryStringVal("code");
    var req = { phone: phone, code: code };
    var url = baseurldataserver + "/qrFlow/sendLink";
    var result = await axios({
      method: "post",
      url: url,
      data: JSON.stringify(req),
      headers: {
        "Content-Type": "application/json",
      },
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
    });

    console.log(result);
    return result.status === 200;
  }

  //--------------GET BACK URL-------------------------
  async getBackUrl() {
    console.log("DID OTP");
    var code = this.getQueryStringVal("code");
    var url = baseurldataserver + "/qrFlow/backUrl/" + code;
    var result = await axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
    });
    var jsonResponse = result.data;
    return jsonResponse.url;
  }

  // --------------CREATE CODE-----------------------
  async createCode() {
    console.log("CreateCode");
    var code = this.getQueryStringVal("code");
    var otpReq = { code: code };
    var url = baseurldataserver + "/qrFlow/createCode";
    var result = await axios({
      method: "post",
      url: url,
      data: JSON.stringify(otpReq),
      headers: {
        "Content-Type": "application/json",
      },
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
    });

    console.log(result);
    return result.status === 200;
  }

  // --------------ASSIGN REG CODE-----------------------
  async assignReg() {
    console.log("Assign Reg");
    var code = this.getQueryStringVal("code");
    var otpReq = { code: code };
    var url = baseurldataserver + "/qrFlow/assignReg";
    var result = await axios({
      method: "post",
      url: url,
      data: JSON.stringify(otpReq),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getToken(),
      },
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
    });

    console.log(result);
    return result.status === 200;
  }

  // --------------CHECK DONE-----------------------
  async checkDone() {
    console.log("CheckDone");
    var code = this.getQueryStringVal("code");
    var url = baseurldataserver + "/qrFlow/checkDone/" + code;
    var result = await axios({
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
    });

    console.log(result);
    return result.data;
  }
  // --------------IS QR CODED REG CODE-----------------------
  isQr() {
    if (!this.qr) {
      var qr = this.getQueryStringVal("qr");
      console.log(qr);
      console.log("QRRR");
      this.qr = qr !== null;
      console.log(this.qr);
      return this.qr;
    } else {
      console.log(this.qr);
      console.log("QRRR");
      return this.qr;
    }
  }

  // --------------VERIFY SMS CODE-------------------

  async otpMobile(opt) {
    if (opt !== "") {
      var otpReq = { code: opt };

      var url = baseurldataserver + "/otp/confirmOtpMobile";
      var result = await axios({
        method: "post",
        url: url,
        data: JSON.stringify(otpReq),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getToken(),
        },
        raxConfig: {
          retry: 3, // number of retry when facing 4xx or 5xx
          noResponseRetries: 3, // number of retry when facing connection error
          onRetryAttempt: (err) => {
            const cfg = rax.getConfig(err);
            console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
          },
        },
      });

      return result.data;
    }
  }

  // --------------VERIFY LOCATION-------------------

  GeolocationPage(
    latitude,
    longitude,
    address,
    locality,
    administrativearealevel2,
    state,
    country,
    postalcode
  ) {
    if (latitude !== "" && longitude !== "") {
      var dataUbication = {
        latitudeUser: latitude,
        longitudeUser: longitude,
        address: encodeURIComponent(address),
        locality: locality,
        administrativearealevel2: administrativearealevel2,
        state: state,
        country: country,
        postalcode: postalcode,
      };
      var url = baseurldataserver + "/registerFlow/addUserLocation";
      axios({
        method: "post",
        url: url,
        data: JSON.stringify(dataUbication),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getToken(),
        },
      })
        .then((response) => {
          console.log("response geoloc!");
          console.log(response);
          return response.data;
        })
        .catch((error) => console.log(error.response, "ERROR"));
    }
  }

  GeolocationPageDireccion(
    latitude,
    longitude,
    address,
    locality,
    administrativearealevel2,
    state,
    country,
    postalcode
  ) {
    if (latitude !== "" && longitude !== "") {
      var dataUbication = {
        latitudeUser: latitude,
        longitudeUser: longitude,
        address: encodeURIComponent(address),
        locality: locality,
        administrativearealevel2: administrativearealevel2,
        state: state,
        country: country,
        postalcode: postalcode,
      };
      var url = baseurldataserver + "/registerFlow/addUserLocation";
      axios({
        method: "post",
        url: url,
        data: JSON.stringify(dataUbication),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getToken(),
        },
      })
        .catch((error) => console.log(error.response, "ERROR"));
        dataUbication.address = address;
        return dataUbication;
    }
  }

  //-----DID normal OTP---------
  async didOtpNormal() {
    console.log("DID OTP");
    var url = baseurldataserver + "/otp/didOtp";
    var otpResult = await axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        Authorization: "Bearer " + this.getToken(),
      },
    });
    var otpJson = otpResult.data;
    if (!otpJson.confirmed) {
      infoMessage("No has verificado tu celular!");
      console.log(otpJson);
      return false;
    }
    console.log("OTP CONFIRMADO");
    return true;
  }

  //--------------WRONG DATA URL-------------------
  async getWrongDataUrl() {
    console.log("DID OTP");
    var url = baseurldataserver + "/registerFlow/wrongDataUrl";
    var result = await axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        Authorization: "Bearer " + this.getToken(),
      },
    });
    var jsonResponse = result.data;
    return jsonResponse.url;
  }
  // --------------OTP EMAIL-------------------
  async didOtpAndMail() {
    console.log("DID OTP");
    var url = baseurldataserver + "/otp/didOtp";
    var emailUrl = baseurldataserver + "/otp/didEmail";
    var emailResponse = await axios({
      method: "get",
      url: emailUrl,
      headers: {
        Authorization: "Bearer " + this.getToken(),
      },
    });
    var emailJson = emailResponse.data;
    if (!emailJson.confirmed) {
      console.log(emailJson);
      infoMessage("No has verificado tu correo!");
      return false;
    }
    console.log("EMAIL CONFIRMADO");
    return true;
  }

  async didEmail() {
    console.log("DID OTP");
    var emailUrl = baseurldataserver + "/otp/didEmail";
    var emailResponse = await axios({
      method: "get",
      url: emailUrl,
      headers: {
        Authorization: "Bearer " + this.getToken(),
      },
    });
    var emailJson = emailResponse.data;
    if (!emailJson.confirmed) {
      console.log(emailJson);
      // infoMessage("No has verificado tu correo!");
      return false;
    }
    console.log("EMAIL CONFIRMADO");
    return true;
  }

  async confirmotp(updateubication) {
    let locationUrl = baseurldataserver + "/registerFlow/userLocation";
    let locationResponse = await axios({
      method: "get",
      url: locationUrl,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        Authorization: "Bearer " + this.getToken(),
      },
    });
    let locationJson = locationResponse.data;

    let dire;
    let arrayDeCadenas = locationJson.address.split(",");

    dire = arrayDeCadenas[0];

    updateubication({
      direccion: dire,
      ciudad: locationJson.state,
      pais: locationJson.country,
      latitud: locationJson.lat,
      longitud: locationJson.lon,
    });
    return locationJson;
  }

  sendInitUser(init) {
    if (init !== "") {
      let code = { nit: init };
      let url = baseurldataserver + "/registerFlow/addNit";
      axios({
        method: "post",
        url: url,
        raxConfig: {
          retry: 3, // number of retry when facing 4xx or 5xx
          noResponseRetries: 3, // number of retry when facing connection error
          onRetryAttempt: (err) => {
            const cfg = rax.getConfig(err);
            console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
          },
        },
        data: JSON.stringify(code),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getToken(),
        },
      })
        .then((response3) => {
          console.log(response3, "AWESOME POST FROM CODE NIT");
        })
        .catch((error) => {
          console.log(error, "ERROR JURIDICA");
        });
    }
  }

  //--------------setRut---------------------------

  setRut(rut) {
    if (rut !== "") {
      let code = { rut: rut };
      let url = baseurldataserver + "/registerFlow/addRut";
      axios({
        method: "post",
        url: url,
        raxConfig: {
          retry: 3, // number of retry when facing 4xx or 5xx
          noResponseRetries: 3, // number of retry when facing connection error
          onRetryAttempt: (err) => {
            const cfg = rax.getConfig(err);
            console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
          },
        },
        data: JSON.stringify(code),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getToken(),
        },
      })
        .then((response3) => {
          console.log(response3, "AWESOME POST FROM CODE RUT");
        })
        .catch((error) => {
          console.log(error, "ERROR AddRut");
        });
    }
  }

  //Execute CV
  async executeCv() {
    console.log("execute CV!!!!!!!!!!");
    var url = baseurldataserver + "/registerFlow/executeCv";
    var res = await axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getToken(),
      },
    });
  }

  //----------SendEmailNew--------------------
  async sendEmailNew() {
    console.log("sendEmail");
    var emailUrl = baseurldataserver + "/otp/sendEmailNew";
    try {
      var emailResponse = await axios({
        method: "get",
        url: emailUrl,
        headers: {
          Authorization: "Bearer " + this.getToken(),
        },
      });
      console.log("EMAIL Enviado");
      addEvent("EMAIL_SENT", {}, {}, "interaction");
      if (emailResponse.status !== 200 && emailResponse.status !== 201) {
        addEvent(
          "EMAIL_ERROR",
          { error: emailResponse.data, status: emailResponse.status },
          {},
          "error"
        );
      }
      return true;
    } catch (err) {
      addEvent("EMAIL_ERROR", { error: err.message }, {}, "error");
    }
  }

  //----------ConfirmEmailNew--------------------
  async confirmEmailNew(code) {
    console.log("confirmEmailNew");
    var emailUrl = baseurldataserver + "/otp/confirmEmailNew?code=" + code;
    var emailResponse = await axios({
      method: "get",
      url: emailUrl,
    }).catch((e) => {
      console.log(e);
    });
    this.token = emailResponse.data.token;
    this.storage.setToken(this.token);
    console.log("EMAIL Confirmado");
    return true;
  }

  //----------SendOtpGps--------------------
  async sendOtpGps() {
    console.log("sendOtpGps");
    var emailUrl = baseurldataserver + "/otp/sendOtp";
    var emailResponse = await axios({
      method: "post",
      url: emailUrl,
      headers: {
        Authorization: "Bearer " + this.getToken(),
      },
    });
    console.log("SMS Enviado");
    return true;
  }

  //----------SendOtpNormal--------------------
  async sendOtp() {
    console.log("Send OTP");
    var emailUrl = baseurldataserver + "/otp/sendOtpMobile";
    try {
      var emailResponse = await axios({
        method: "post",
        url: emailUrl,
        headers: {
          Authorization: "Bearer " + this.getToken(),
        },
      });
      if (emailResponse.status === 200) {
        addEvent("OTP_SENT", {}, {}, "interaction");
      } else {
        addEvent(
          "OTP_ERROR",
          { error: `status code: ${emailResponse.status}` },
          {},
          "interaction"
        );
      }
    } catch (err) {
      addEvent("OTP_ERROR", { error: err.message }, {}, "error");
    }
    console.log("SMS Enviado");
    return true;
  }

  //----------------ModifyEmail----------------
  async modifyEmail(email) {
    var url = baseurldataserver + "/registerFlow/modifyEmail";
    var req = {
      email: email,
    };
    return axios({
      method: "post",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      data: JSON.stringify(req),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getToken(),
      },
    });
  }

  //----------------ModifyEmail----------------
  async modifyPhone(phone) {
    var url = baseurldataserver + "/registerFlow/modifyPhone";
    var req = {
      phone: phone,
    };
    return axios({
      method: "post",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      data: JSON.stringify(req),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getToken(),
      },
    });
  }

  //----------Log--------------------
  async log(data, method) {
    try {
      var url = baseurldataserver + "/logger";
      var req = {
        data: "method: " + method + ", data: " + data,
      };
      axios({
        method: "post",
        url: url,
        raxConfig: {
          retry: 3, // number of retry when facing 4xx or 5xx
          noResponseRetries: 3, // number of retry when facing connection error
          onRetryAttempt: (err) => {
            const cfg = rax.getConfig(err);
            console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
          },
        },
        data: JSON.stringify(req),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getToken(),
        },
      }).catch((e) => {});
    } catch (e) {}
  }

  async final(gotoClose) {
    var url = baseurldataserver + "/registerFlow/verifyRegistration";
    var res1 = await axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getToken(),
      },
    });
    console.log("verifyRegistration");
    console.log(res1.data, "RES1 DATA");
    url = baseurldataserver + "/registerFlow/unifiedChecks";
    if (res1.status !== 200) return;
    var responseChecks = await axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getToken(),
      },
    });
    console.log("unifiedChecks");
    console.log(responseChecks.data);
    var pasaUnifiedChecks = this.handleUnifiedChecks(responseChecks.data);
    //Handle wrong unified checks
    if (!pasaUnifiedChecks) {
      infoMessage(
        "Una de las fases de verificación no fue exitosa, nos pondremos en contacto contigo."
      ).then(() => {
        infoMessage("Ya puedes cerrar esta ventana");
      });
    }
    url = baseurldataserver + "/registerFlow/executeWebHook";
    if (res1.status === 200 && pasaUnifiedChecks) {
      try {
        var res2 = await axios({
          method: "get",
          url: url,
          raxConfig: {
            retry: 3, // number of retry when facing 4xx or 5xx
            noResponseRetries: 3, // number of retry when facing connection error
            onRetryAttempt: (err) => {
              const cfg = rax.getConfig(err);
              console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
            },
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.getToken(),
          },
        });
      } catch (e) {}
      if (!this.isQr()) {
        window.location = res1.data.redirectionUrl;
      } else {
        // this.gotoCloseWindowScreen();
        gotoClose();
        // this.history.push("/closeWindow");
        // window.location.href = 'https://api.anteia.co/final';
        //creo que ya esta(?)
      }
    }
  }
  //QUITAR PARA DEV
  handleUnifiedChecks(checks) {
    if (!isProd()) return true;
    return true;
    if (checks.listsCheck.active && !checks.listsCheck.result) {
      return false;
    } else {
      return true;
    }
  }

  async sendPhoneLink(matiId, needsFace = false) {
    console.log("SendPhoneLink");
    var data = {
      matiId: matiId,
      needsFace: needsFace,
    };
    console.log("Sending Link for extra phone photo");
    console.log(data);
    var url = baseurldataserver + "/phonePhoto";
    let res = await axios({
      method: "post",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      data: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getToken(),
      },
    });
  }

  async checkPhonePhoto() {
    console.log("Checking phone photo...");
    var url = baseurldataserver + "/phonePhoto/check";
    let res = await axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getToken(),
      },
    });
    console.log(res);
    return res.data.confirmed;
  }

  initialRegistration(code, proj, setToken, setRegistrationId) {
    console.log("INITIAL REGISTRATIONOOOOOOOOO");
    var data = { projectId: proj.id, code: code };
    console.log(data);
    var url = baseurldataserver + "/registerFlow/initialRegistration";
    return axios({
      method: "post",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      data: JSON.stringify(data),

      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((resp) => {
        var response = resp.data;
        console.log("RegistraitonID from response: " + response.registrationId);
        setRegistrationId(response.registrationId);
        console.log("RegistraitonID from response: " + response.registrationId);
        console.log(response);
        console.log(response.userToken);
        setToken(response.userToken);
        this.token = response.userToken;
        this.storage.setToken(response.userToken);
        this.addUserDeviceInformation(response.userToken);
        setInfo(response.registrationId, proj.clientId, proj.id);
        addEvent("INITIAL_REG", {}, {}, "interaction");
        if (this.isQr()) {
          this.assignReg();
        }
      })
      .catch((e) => alert(e));
  }
  /**
   *
   * @param {string} nit
   */
  async addNit(nit) {
    console.log("Add NIT: " + nit);
    var postUrl = baseurldataserver + "/registerFlow/addNit";
    var body = {
      nit: nit,
    };
    try {
      var result = await axios({
        method: "post",
        url: postUrl,
        raxConfig: {
          retry: 3, // number of retry when facing 4xx or 5xx
          noResponseRetries: 3, // number of retry when facing connection error
          onRetryAttempt: (err) => {
            const cfg = rax.getConfig(err);
            console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
          },
        },
        data: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getToken(),
        },
      });
      if (!(result.status === 200 || result.status === 201)) {
        alert("Error enviando NIT");
      }
    } catch (e) {
      commonError("Error enviando el NIT");
    }
  }
  /**
   *
   * @param {*} id
   */
  async addUserId(id, nombre, apellido) {
    console.log("Add User Id: " + id);
    var postUrl = baseurldataserver + "/registerFlow/addUserIdentification";
    var body = {
      identification: id,
      identificationType: "CedulaCiudadania",
      country: "colombia",
      expDate: "",
      birthPlace: "",
      height: "",
      rh: "",
      sex: "",
      birthDate: "",
      pdf417info: "",
      accuracy: "",
      name: nombre,
      lastName: apellido,
    };
    var result = await axios({
      method: "post",
      url: postUrl,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      data: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getToken(),
      },
    });
    if (!(result.status === 200 || result.status === 201)) {
      alert("Error enviando NIT");
    }
  }

  async addUserInitialInformation(cc, lastName, email, phone, docType, name) {
    let postUrl = baseurldataserver + "/registerFlow/addInitialInformation";
    let body = {
      lastName: lastName,
      cellphone: phone,
      email: email,
      identification: cc,
      documentType: docType,
      name: name,
    };
    console.log("Initial Info");
    console.log(body);
    try {
      let result = await axios({
        method: "post",
        url: postUrl,
        raxConfig: {
          retry: 3, // number of retry when facing 4xx or 5xx
          noResponseRetries: 3, // number of retry when facing connection error
          onRetryAttempt: (err) => {
            const cfg = rax.getConfig(err);
            console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
          },
        },
        data: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getToken(),
        },
      });
      if (!(result.status === 200 || result.status === 201)) {
        commonError("Error enviando información inicial: " + result.status);
      }
      addEvent("INITIAL_DATA", {}, {}, "interaction");
    } catch (e) {
      commonError("Error enviando información inicial: " + e);
    }
  }

  async addUserDeviceInformation() {
    console.log("ADD USER DEVICE INFO with TOKEN: " + this.getToken());
    var ip = "BLOCKED";
    try {
      var response = await fetch("https://api.ipify.org/");
      ip = await response.text();
    } catch (e) {
      addEvent("DEVICEID_ERROR", { error: e.message }, {}, "error");
    }

    var url = baseurldataserver + "/registerFlow/addDeviceInfo";
    var deviceID = "";
    if (MediaDeviceInfo) deviceID = MediaDeviceInfo.deviceId;
    var dataf = {
      os: window.navigator.userAgent,
      deviceId: deviceID,
      // screenHeigh: document.documentElement.clientHeight,
      screenHeigh: window.screen.height,
      // screenWidth: document.documentElement.clientWidth,
      screenWidth: window.screen.width,
      trusted: true,
      type: "Web",
      ip: ip,
      numCpus: window.navigator.hardwareConcurrency,
    };
    this.setDeviceId(dataf);
    console.log(dataf);
    console.log("TOKEN: " + this.getToken());
    fetch(url, {
      method: "POST",
      body: JSON.stringify(dataf),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getToken(),
      },
    })
      .catch((error) => {
        addEvent("DEVICEID_ERROR", { error: error.message }, {}, "error");
      })
      .then((res2) => res2.json())
      .then((response2) => {
        addEvent("DEVICEID_SET", {}, {}, "interaction");
        console.log(response2);
      });
  }

  async checkLists() {
    console.log("Checking Lists...");
    var url = baseurldataserver + "/registerFlow/executeLists";
    try {
      let r = await axios({
        method: "get",
        url: url,
        raxConfig: {
          retry: 3, // number of retry when facing 4xx or 5xx
          noResponseRetries: 3, // number of retry when facing connection error
          onRetryAttempt: (err) => {
            const cfg = rax.getConfig(err);
            console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
          },
        },
        headers: {
          Authorization: "Bearer " + this.getToken(),
        },
      });
      console.log("Checking Lists... DONE!");
      console.log(r);
    } catch (e) {
      console.log(e);
    }
  }

  /**
   *
   * @returns the preloaded data
   */
  async getPreloadedData() {
    const code = this.getQueryStringVal("code");
    const url =
      baseurldataserver + "/registerFlow/getPreloadedData?code=" + code;
    console.log(url);
    const result = await axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        Authorization: "Bearer " + this.getToken(),
      },
    }).catch((e) => {
      console.log(e);
      commonError("Error cargando datos");
      return;
    });
    if (!(result.status === 200 || result.status === 201)) {
      commonError("Error cargando datos: " + result.status);
      return;
    }
    return result.data;
  }

  /**
   *
   * @returns true if data done, false if not done
   */
  async getDataDone() {
    // if (!isProd()) {
    //   // await this.forceDataDone();
    //   return true;
    // }
    // await this.forceDataDone();
    // return true;
    // if (!this.token) return;
    const code = this.getQueryStringVal("code");
    const url = baseurldataserver + "/registerFlow/dataDone";
    console.log(url);
    const result = await axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        Authorization: "Bearer " + this.getToken(),
      },
    });
    if (!(result.status === 200 || result.status === 201)) {
      commonError("Error cargando datos: " + result.status);
      return;
    }
    var jsonResponse = result.data;
    return jsonResponse.dataDone;
  }

  /**
   *
   * @returns the checks
   */
  async getChecks() {
    if (!this.token) return;
    const code = this.getQueryStringVal("code");
    const url = baseurldataserver + "/registerFlow/unifiedChecks";
    console.log(url);
    const result = await axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        Authorization: "Bearer " + this.getToken(),
      },
    });
    if (!(result.status === 200 || result.status === 201)) {
      commonError("Error cargando datos: " + result.status);
      return;
    }
    return result.data;
  }

  async checkFacematch() {
    console.log("CHECKING FACEMAAAAAAAAAAAAAAAAATCH");
    let encToken = this.getToken();
    let decToken = this.parseJwt(encToken);
    let regId = decToken.registrationId;
    console.log("regId: " + regId);
    let url = baseurldataserver + "/savePicture/comparar/" + regId;
    try {
      var resp = await axios({
        method: "get",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getToken(),
        },
      });
      let data = resp.data;
      console.log(data);
      return data.Result;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
  /**
   * GETS auth request by code
   * @param } code
   * @returns
   */
  async getAuthRequest(code) {
    console.log("GettingAuthRequest: " + code);
    let url = baseurldataserver + "/authProcess/byCode/" + code;

    try {
      var resp = await axios({
        method: "get",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
      });
      let data = resp.data;
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async setAuthResult(code, score) {
    let req = {
      result: score,
    };
    console.log("SettingResult");
    console.log(req);
    let url = baseurldataserver + "/authProcess/setResult/" + code;
    try {
      var result = await axios({
        method: "post",
        url: url,
        raxConfig: {
          retry: 3, // number of retry when facing 4xx or 5xx
          noResponseRetries: 3, // number of retry when facing connection error
          onRetryAttempt: (err) => {
            const cfg = rax.getConfig(err);
            console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
          },
        },
        data: JSON.stringify(req),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!(result.status === 200 || result.status === 201)) {
        commonError("Error enviando setResult: " + result.status);
      }
    } catch (e) {
      commonError("Error enviando setREsult: " + e);
    }
  }

  /**
   * gets the brand config
   * @param {string} code
   */
  async getBrandConfig(code) {
    console.log("Getting BrandConfig: " + code);
    let url = baseurldataserver + "/brandConfig/byCode/" + code;

    try {
      var resp = await axios({
        method: "get",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
      });
      let data = resp.data;
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  /**
   * gets the brand config
   * @param {string} code
   */
  async getBrandConfigAuth(code) {
    console.log("Getting BrandConfig: " + code);
    let url = baseurldataserver + "/brandConfig/byAuthCode/" + code;

    try {
      var resp = await axios({
        method: "get",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
      });
      let data = resp.data;
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  getQuestions(setQuestions) {
    var url = baseurldataserver + "/experian/questions";
    return axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getToken(),
      },
    })
      .then((response) => {
        console.log("getQuestions");
        console.log(response.data);

        setQuestions(response.data);
      })
      .catch((e) => alert(e));
  }

  async getProject() {
    console.log("GettingPRoject");
    let url = baseurldataserver + "/registerFlow/getProject";

    try {
      var resp = await axios({
        method: "get",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getToken(),
        },
      });
      let data = resp.data;
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async getRegistrationData() {
    console.log("GettingRegistrationData");
    let url = baseurldataserver + "/registerFlow/status";
    try {
      var resp = await axios({
        method: "get",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getToken(),
        },
      });
      let data = resp.data;

      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async confirmData(data) {
    console.log("confirmingData");
    let url = baseurldataserver + "/registerFlow/confirmData";
    try {
      var resp = await axios({
        method: "post",
        url: url,
        data: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getToken(),
        },
      });
      let data2 = resp.data;
      console.log(data2);
      return data2;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async checkPhoneEmail(email2, phone2) {
    console.log("Checking email and phone");
    let url = baseurldataserver + "/registerFlow/checkEmailPhoneExists";
    let data2 = {
      email: email2,
      phone: phone2,
    };
    console.log(data2);
    try {
      let resp = await axios({
        method: "post",
        url: url,
        data: JSON.stringify(data2),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getToken(),
        },
      });
      let data = resp.data;
      console.log(data);
      return data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getProjectByCode(code) {
    console.log("getProjectByCode");
    let url = baseurldataserver + "/registerFlow/getProjectByCode?code=" + code;
    try {
      let resp = await axios({
        method: "get",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
      });
      let data = resp.data;
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async spoolUp() {
    console.log("Spooling Up!");
    let url = baseurldataserver + "/savePicture/spoolUp";
    try {
      var resp = await axios({
        method: "get",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(resp);
    } catch (e) {
      console.log(e);
    }
  }

  getQuery = () => {
    if (typeof window !== "undefined") {
      return new URLSearchParams(window.location.search);
    }
    return new URLSearchParams();
  };

  getQueryStringVal = (key) => {
    return this.getQuery().get(key);
  };

  parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  async getFaceImages() {
    var url = baseurldataserver + "/registerFlow/getFacePics";
    var result = await axios({
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getToken(),
      },
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
    });

    return result.data;
  }
}
