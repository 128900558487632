import Api from "../components/api/api";
import Geocode from "react-geocode";
import { infoMessage } from "../components/utils/ErrorHandler/errorHandler";
import { addEvent } from "../components/api/Events/EventsApi";
/*   ---------------------GEOLOCATION---------------------------- */
function getGeolocation({ api }) {
  let stateGps = false;
  const SuccessGps = (position) => {
    addEvent("GPS_ACCEPT", {}, {}, "interaction");
    Geocode.setApiKey("AIzaSyDdT6vOLnI4C_rEgapqWGakLqUFyMEFRik");
    Geocode.setLanguage("en");
    // Geocode.setLocationType("ROOFTOP");
    Geocode.enableDebug();
    Geocode.fromLatLng(position.coords.latitude, position.coords.longitude)
      .then((response) => {
        addEvent(
          "GEOREF_ACCEPT",
          { lon: position.coords.longitude, lat: position.coords.latitude },
          {},
          "interaction"
        );
        const address = response.results[0].formatted_address;
        let latitude,
          longitude,
          locality,
          administrativearealevel2,
          state,
          country,
          postalcode;
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                locality = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_2":
                administrativearealevel2 =
                  response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
              case "postal_code":
                postalcode =
                  response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        if (stateGps === false) {
          console.log("geolocation SENDING>.........");
          console.log(stateGps, "STATEGPS");
          api.GeolocationPage(
            latitude,
            longitude,
            address,
            locality,
            administrativearealevel2,
            state,
            country,
            postalcode
          );
          stateGps = true;
        } else {
          console.log("call gps method off");
        }
      })
      .catch((e) => {
        addEvent("GEOREF_ERROR", { error: e.message, lon: position.coords.longitude, lat: position.coords.latitude }, {}, "error");
        ErrorGps(e);
      });
  };

  const ErrorGps = (error) => {
    api.log(error, "GPS ERROR");
    addEvent("GPS_ERROR", { error: error.message }, {}, "error");
  };

  navigator.geolocation.getCurrentPosition(SuccessGps, ErrorGps);
}
export default getGeolocation;
